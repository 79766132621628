import React from "react";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Alert, {type AlertProps} from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";

interface DWAlertProps {
    /** True if the alert is open, false if it is closed */
    openAlert: boolean;
    /** Function that sets the open state of the alert */
    onClose: () => void;
    /** The message inside the alert */
    alertMessage: string | undefined;
    /** Either success, info, warning, or error */
    alertSeverity: AlertProps["severity"];
}

/**
 * A custom alert component that is used to display messages to the user.
 * @returns {React.FC<DWAlertProps>} A custom alert component
 */
export const DWAlert: React.FC<DWAlertProps> = props => {
    const {openAlert, onClose, alertMessage, alertSeverity} = props;

    return (
        <Snackbar open={openAlert} autoHideDuration={5000} onClose={onClose}>
            <Alert
                severity={alertSeverity}
                action={
                    <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{mb: 2, width: "100%"}}>
                {alertMessage ?? ""}
            </Alert>
        </Snackbar>
    );
};
