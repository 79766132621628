import React, {createContext, useContext, useState} from "react";
import {apiGetGeojsonUrl, apiGetGeojsonZip, apiGetMyProjects, getProjectUsers, apiRequest} from "react_ct/requests";
import type {ProjectUser, Project, ManualTagSQL, ManualTag, MapScan, Scan} from "react_ct/types";
import {AuthContext} from "./AuthContext";
import {useQuery} from "@tanstack/react-query";
import {geojsonKeys, getCommunityGeojsonData, getGeojsonByType, projectKeys, scanKeys} from "queries/queries";
import {convertTagColorToHex} from "pages/portal/collect/ProjectTags";
import {INVALID_STAGES, STAGE_MAP} from "pages/portal/collect/components/constants";

interface ProjectContextType {
    projectList: Project[];
    currentProject?: Project;
    changeProject?: React.Dispatch<React.SetStateAction<Project | undefined>>;
    projectsError?: string;
    areProjectsPending?: boolean;
    projectUser?: ProjectUser;
    projectUserError?: string;
    projectUserList?: ProjectUser[];
    projectUserListError?: string;
    projectManualTags?: ManualTag[];
    projectManualTagsError?: string;
    projectScans?: MapScan[];
    projectScansError?: string;
    areProjectScansPending?: boolean;
}

interface ProjectProviderProps {
    children: JSX.Element;
}

export const ProjectContext = createContext<ProjectContextType>({
    projectList: [],
    currentProject: undefined,
    changeProject: undefined,
    projectsError: undefined,
});

const useProject = (): ProjectContextType => {
    return useContext(ProjectContext);
};

const ProjectProvider: React.FC<ProjectProviderProps> = (props: ProjectProviderProps) => {
    const {children} = props;

    const auth = useContext(AuthContext);
    const {user} = auth;

    const [currentProject, setCurrentProject] = useState<Project | undefined>(undefined);

    // project related queries

    const {
        data: projectList,
        error: projectsError,
        isPending: areProjectsPending,
    } = useQuery({
        queryKey: projectKeys.projects(user?.id),
        queryFn: async () => {
            if (user) return await apiGetMyProjects();
            else throw new Error("Could not retrieve user");
        },
    });

    useQuery({
        queryKey: geojsonKeys.allGeojsonZips(currentProject?.id),
        queryFn: async () => await apiGetGeojsonZip(Number(currentProject?.id)),
        enabled: !!currentProject,
    });

    useQuery({
        queryKey: geojsonKeys.allGeojsonUrls(currentProject?.id),
        queryFn: async () => await apiGetGeojsonUrl(Number(currentProject?.id)),
        enabled: !!currentProject,
    });

    useQuery({
        queryKey: geojsonKeys.featuresGeojson(currentProject?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!currentProject,
    });

    useQuery({
        queryKey: geojsonKeys.individualFeaturesGeojson(currentProject?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!currentProject,
    });

    useQuery({
        queryKey: geojsonKeys.obstructionsGeojson(currentProject?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!currentProject,
    });

    useQuery({
        queryKey: geojsonKeys.communityGeojson(currentProject?.id),
        queryFn: async () => await getCommunityGeojsonData(currentProject),
        enabled: !!currentProject,
    });

    const {data: projectManualTags, error: projectManualTagsError} = useQuery({
        queryKey: projectKeys.projectManualTags(currentProject?.id),
        queryFn: async () => {
            if (currentProject) {
                const res = await apiRequest({path: `project/config/${currentProject.id}/manualTags`});
                const manualTagsRes: ManualTagSQL[] = res.data.manualTags;
                const manualTags: ManualTag[] = manualTagsRes.map(tag => convertTagColorToHex(tag));
                return manualTags;
            }
        },
        enabled: !!currentProject,
    });

    const {data: projectUser, error: projectUserError} = useQuery({
        queryKey: projectKeys.getProjectUser(currentProject?.id, user?.id),
        queryFn: async () => {
            if (currentProject && user) {
                const projectUsers = await getProjectUsers(currentProject.id);
                if (!projectUsers?.length) throw new Error("No project users available");
                const projectUser = projectUsers.filter(projectUser => projectUser.userId === user.id)[0];
                if (projectUser === undefined) throw new Error("Could not find a user for this project");
                return projectUser;
            } else throw new Error("No project or auth user data available");
        },
        enabled: !!currentProject?.id && !!user?.id,
    });

    const {data: allProjectUsers, error: projectUsersError} = useQuery({
        queryKey: projectKeys.projectUsers(currentProject?.id),
        queryFn: async () => {
            if (currentProject) {
                const projectUsers = await getProjectUsers(currentProject.id);
                return projectUsers;
            } else {
                throw new Error("Could not load project");
            }
        },
        enabled: !!currentProject,
    });

    const {
        data: projectScans,
        error: projectScansError,
        isPending: areProjectScansPending,
    } = useQuery({
        queryKey: scanKeys.scans(currentProject?.id),
        queryFn: async () => {
            if (allProjectUsers) {
                const {data} = await apiRequest({
                    path: "scan",
                    params: {
                        scannerIds: allProjectUsers.map((member: ProjectUser) => member.userId),
                    },
                });
                const updatedData: MapScan[] = data
                    .filter((scan: Scan) => {
                        const dbStage = scan.stage ? String(scan.stage) : undefined;
                        return dbStage && !INVALID_STAGES.includes(dbStage);
                    })
                    .map((scan: Scan) => {
                        const coords = scan.robustGpsCoordinates ?? scan.gpsCoordinates;
                        const latlng = coords?.replace("POINT(", "").replace(")", "").split(" ");
                        const lat = parseFloat(latlng?.[0] ?? "");
                        const lng = parseFloat(latlng?.[1] ?? "");
                        return {
                            ...scan,
                            lat,
                            lng,
                            stage: STAGE_MAP[scan.stage],
                            scannerId: scan.userId,
                            gpsMultiline: scan.gpsMultiline?.map(coord => [coord[1], coord[0]]),
                            adjustedGpsMultiline: scan.adjustedGpsMultiline?.map(coord => [coord[1], coord[0]]),
                        };
                    });
                return updatedData;
            } else {
                throw new Error("Could not retrieve scans");
            }
        },
        enabled: !!allProjectUsers,
    });

    React.useEffect(() => {
        if (projectList) setCurrentProject(projectList[0]);
    }, [projectList]);

    return (
        <ProjectContext.Provider
            value={{
                projectList: projectList ?? [],
                currentProject,
                changeProject: setCurrentProject,
                projectsError: projectsError?.message,
                areProjectsPending,
                projectUser,
                projectUserError: projectUserError?.message,
                projectUserList: allProjectUsers ?? [],
                projectUserListError: projectUsersError?.message,
                projectManualTags,
                projectManualTagsError: projectManualTagsError?.message,
                projectScans,
                projectScansError: projectScansError?.message,
                areProjectScansPending,
            }}>
            {children}
        </ProjectContext.Provider>
    );
};

export {useProject, ProjectProvider};
