import {Tune} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import React from "react";

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    gap: 4,
    flexFlow: "row wrap",
    "& .MuiToggleButtonGroup-grouped": {
        border: "none",
        padding: "2px 10px",
        fontSize: "0.9rem",
        borderRadius: "50px",

        "&.Mui-selected": {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
        },
    },
    "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
        borderRadius: "50px",
    },
    "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
        borderRadius: "50px",
    },
}));

export default function FilterMenu({
    areViolationsVisible,
    setViolationsVisible,
    arePolygonsVisible,
    setPolygonsVisible,
    areDisplacementsVisible,
    setDisplacementsVisible,
}: {
    areViolationsVisible: string[];
    setViolationsVisible: React.Dispatch<React.SetStateAction<string[]>>;
    arePolygonsVisible: string[];
    setPolygonsVisible: React.Dispatch<React.SetStateAction<string[]>>;
    areDisplacementsVisible: string[];
    setDisplacementsVisible: React.Dispatch<React.SetStateAction<string[]>>;
}): React.ReactElement {
    const [open, setOpen] = React.useState<boolean>(false);

    const handleChange = (): void => {
        setOpen(prev => !prev);
    };

    const violations = ["cross_slope", "run_slope", "clear_width"];
    const polygons = ["Panel", "Deterioration", "Gravel", "Cracked", "Asphalt", "Obstruction", "Stairs", "Brick"];
    const displacements = [
        "Deterioration",
        "Driveway",
        "Horizontal",
        "Utility Box",
        "Overhang",
        "Tree Hazard",
        "Vertical",
        "Trip Hazard",
    ];

    const handleToggleViolation = (event: React.MouseEvent<HTMLElement>, updatedViolations: string[]): void => {
        setViolationsVisible(updatedViolations);
    };

    return (
        <>
            <Tooltip title="Annotation Toggle" placement="left" arrow>
                <IconButton
                    disableRipple
                    aria-label="Annotation Toggle"
                    onClick={handleChange}
                    sx={theme => ({
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "4px",
                        "&.MuiButtonBase-root": {
                            flexBasis: "40px",
                        },
                    })}>
                    <Tune />
                </IconButton>
            </Tooltip>
            <Collapse in={open}>
                <Paper sx={{px: 2, py: 1, maxWidth: "300px", display: open ? "block" : "none"}}>
                    <Typography fontSize="0.8rem" fontWeight="bold" mb={1}>
                        Violations
                    </Typography>
                    <CustomToggleButtonGroup
                        value={areViolationsVisible}
                        aria-label="Filter violations"
                        color="info"
                        size="small"
                        onChange={handleToggleViolation}>
                        {violations.map(violation => {
                            const violationName =
                                violation.split("_")[0][0].toUpperCase() +
                                violation.split("_")[0].substring(1) +
                                " " +
                                violation.split("_")[1];
                            return (
                                <ToggleButton
                                    key={violation}
                                    value={violation}
                                    aria-label={`Filter ${violationName}`}
                                    color="info">
                                    {violationName}
                                </ToggleButton>
                            );
                        })}
                    </CustomToggleButtonGroup>
                    <Divider
                        sx={{
                            my: 2,
                        }}
                    />
                    <Typography fontSize="0.8rem" fontWeight="bold" my={1}>
                        Regions
                    </Typography>
                    <CustomToggleButtonGroup
                        value={arePolygonsVisible}
                        aria-label="Filter polygons"
                        color="info"
                        size="small"
                        onChange={(event: React.MouseEvent<HTMLElement>, updatedPolygons: string[]) =>
                            setPolygonsVisible(updatedPolygons)
                        }>
                        {polygons.map(polygon => (
                            <ToggleButton key={polygon} value={polygon} aria-label={`Filter ${polygon}`} color="info">
                                {polygon}
                            </ToggleButton>
                        ))}
                    </CustomToggleButtonGroup>
                    <Divider
                        sx={{
                            my: 2,
                        }}
                    />
                    <Typography fontSize="0.8rem" fontWeight="bold" my={1}>
                        Manual Tags
                    </Typography>
                    <CustomToggleButtonGroup
                        value={areDisplacementsVisible}
                        aria-label="Filter displacements"
                        color="info"
                        size="small"
                        onChange={(event: React.MouseEvent<HTMLElement>, updatedDisplacements: string[]) =>
                            setDisplacementsVisible(updatedDisplacements)
                        }>
                        {displacements.map(displacement => (
                            <ToggleButton
                                key={displacement}
                                value={displacement}
                                aria-label={`Filter ${displacement}`}
                                color="info">
                                {displacement}
                            </ToggleButton>
                        ))}
                    </CustomToggleButtonGroup>
                </Paper>
            </Collapse>
        </>
    );
}
