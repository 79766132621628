/* eslint-disable no-console */
import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import {App} from "app/App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/service-worker.js")
        .then(function (registration) {
            console.log("Service Worker registered with scope:", registration.scope);
        })
        .catch(function (error) {
            console.log("Service Worker registration failed:", error);
        });
}
