import {type AxiosResponse} from "axios";
import {z} from "zod";

import {type Scan, scanSchema, type Webmap, type MapScan, mapScanSchema} from "react_ct/types";
import {apiRequest} from ".";
import {request} from "@esri/arcgis-rest-request";

const rasterInfoSchema = z.object({
    imageUrl: z.string().optional(),
    rotatedImageUrl: z.string().optional(),
    segmentationsJsonUrl: z.string().optional(),
    heatmapUrl: z.string().optional(),
    posesOverlayUrl: z.string().optional(),
    posesUrl: z.string().optional(),
    posesJsonUrl: z.string().optional(),
    depthUrl: z.string().optional(),
    oldRasterUrl: z.string().optional(),
    evalUrl: z.string().optional(),
});

export const apiGetScansFromFoldernames = async (folderNames: string[]): Promise<MapScan[]> => {
    const {data} = await apiRequest({path: `scan/scanId`, params: {folderNames}});
    const scanIds: number[] = data as number[];
    const {data: scanData} = await apiRequest({path: "scan", params: {scanIds}});
    const parsedScans = z.array(mapScanSchema).safeParse(scanData);
    if (!parsedScans.success) {
        console.warn("Zod error: ", parsedScans.error);
        return scanData as MapScan[];
    }
    return parsedScans.data;
};

export type RasterInfoType = z.infer<typeof rasterInfoSchema>;

export const apiGetRasterInfo = async (scanId: number): Promise<RasterInfoType> => {
    const {data} = await apiRequest({
        path: `scan/read-urls`,
        params: {scanId, evalUrl: true},
    });
    const parsedResponse = rasterInfoSchema.safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        return data as RasterInfoType;
    }
    return parsedResponse.data;
};

export const apiGetAllReadUrls = async (scanId: number): Promise<RasterInfoType> => {
    const {data} = await apiRequest({
        path: `scan/read-urls`,
        params: {
            scanId,
            imageUrl: true,
            rotatedImageUrl: true,
            segmentationsJsonUrl: true,
            heatmapUrl: true,
            posesOverlayUrl: true,
            posesUrl: true,
            posesJsonUrl: true,
            depthUrl: true,
            oldRasterUrl: true,
            evalUrl: true,
        },
    });

    const parsedResponse = rasterInfoSchema.safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        return data as RasterInfoType;
    }
    return parsedResponse.data;
};

const geojsonZippedSchema = z.object({
    geojsonUrl: z.string().optional(),
    individualFeaturesUrl: z.string().optional(),
    obstructionsUrl: z.string().optional(),
    lastModified: z.string().optional(),
});

export type GeojsonZipType = z.infer<typeof geojsonZippedSchema>;

export const apiGetGeojsonZip = async (
    projectId: number,
    retrieveTypes: {
        geojson?: boolean;
        obstructions?: boolean;
        indvfeatures?: boolean;
    } = {
        geojson: true,
        obstructions: true,
        indvfeatures: true,
    },
): Promise<GeojsonZipType> => {
    const {data} = await apiRequest({
        path: `project/${projectId}/geojson/zip`,
        params: {
            projectId,
            geojson: retrieveTypes.geojson,
            obstructions: retrieveTypes.obstructions,
            indvfeatures: retrieveTypes.indvfeatures,
        },
    });

    const parsedReponse = geojsonZippedSchema.safeParse(data);
    if (!parsedReponse.success) {
        return data as GeojsonZipType;
    }
    return parsedReponse.data;
};

const geojsonRawSchema = z.object({
    geojsonUrl: z.string(),
    lastModified: z.string().optional(),
});

export const apiGetGeojsonUrl = async (projectId: number, type?: string): Promise<string> => {
    const {data} = await apiRequest({
        path: `project/${projectId}/geojson/${type ?? ""}`,
    });

    const parsedResponse = geojsonRawSchema.safeParse(data);

    if (!parsedResponse.success) {
        return data.geojsonUrl as string;
    }

    return parsedResponse.data.geojsonUrl;
};

export const apiArchiveScan = async (scanId: number): Promise<AxiosResponse> => {
    return await apiRequest({path: `scan?scanId=${scanId}`, method: "PUT", data: {stage: "archive"}});
};

export const putScanStage = async (scanId: number, stage: string): Promise<AxiosResponse> => {
    return await apiRequest({path: `scan`, method: "PUT", params: {scanId}, data: {stage}});
};

export const putScanAdjustedMulitline = async (
    scanId: number,
    multiline: Array<[number, number]>,
): Promise<AxiosResponse> => {
    return await apiRequest({path: `scan`, method: "PUT", params: {scanId}, data: {adjustedGpsMultiline: multiline}});
};

export const downloadFromS3 = async (url: string): Promise<Response> => {
    return await fetch(url);
};

export const getScansByStage = async (stages: string[], includeProjectInformation: boolean): Promise<Scan[]> => {
    if (stages.length === 0) {
        return [];
    }

    const {data} = await apiRequest({
        path: `scan`,
        params: {"stages[]": stages, includeProjectInformation},
    });
    const parsedResponse = z.array(scanSchema).safeParse(data);
    if (!parsedResponse.success) {
        console.warn("Zod error: ", parsedResponse.error);
        return data as Scan[];
    }

    return parsedResponse.data;
};

export const createWebmap = async (projectId: number, userId: number, esriToken: string): Promise<void> => {
    await apiRequest({
        path: `webmap`,
        method: "POST",
        data: {userId, esriToken, projectId},
    });
};

export const getWebmaps = async (projectId: number): Promise<Webmap[]> => {
    const {data} = await apiRequest({
        path: `webmap`,
        method: "GET",
        params: {projectId},
    });

    return data;
};

export const deleteWebmap = async (webmapId: number, projectId: number): Promise<void> => {
    await apiRequest({
        path: `webmap/${webmapId}`,
        method: "DELETE",
        params: {projectId},
    });
};

export const getScansByProjectId = async (projectId: number): Promise<Scan[]> => {
    const {data} = await apiRequest({path: `scan`, params: {"projectIds[]": projectId}});
    const parsedResponse = z.array(scanSchema).safeParse(data);
    if (!parsedResponse.success) {
        return data as Scan[];
    }

    return parsedResponse.data;
};

export const getScansByProjectIds = async (projectIds: number[]): Promise<Scan[]> => {
    const {data} = await apiRequest({path: `scan`, params: {"projectIds[]": projectIds}});
    const parsedResponse = z.array(scanSchema).safeParse(data);
    if (!parsedResponse.success) {
        return data as Scan[];
    }

    return parsedResponse.data;
};

export const apiReverseGeocode = async (location: {x: number; y: number}): Promise<string> => {
    const esriToken = process.env.REACT_APP_ESRI_API_KEY;
    try {
        const response = await request(
            `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode`,
            {
                params: {
                    location: `${location.x},${location.y}`,
                    token: esriToken,
                    featureTypes: "StreetAddress",
                },
            },
        );
        return response.address?.ShortLabel ? String(response.address.ShortLabel) : `(${location.x}, ${location.y})`;
    } catch (error) {
        console.error(error);
        return `(${location.x}, ${location.y})`;
    }
};
