import React from "react";
import {Alert, type AlertColor, Box, Fab, Snackbar, Tooltip, Stack, Button, styled} from "@mui/material";
import DWMap from "components/Map/DWMap";
import {CheckCircleOutline, ErrorOutline, Save, Upload} from "@mui/icons-material";
import {apiRequest} from "react_ct/requests";
import {useProject} from "contexts/ProjectContext";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {createGeoJSONFeatureCollection} from "components/Map/helpers";
import {colors} from "react_ct/theme";
import LoadingScreen from "components/LoadingScreen";
import {ShiftControl} from "components/Map/MapComponents";
import LoadingError from "components/LoadingError";
import {isEqual} from "lodash";
import {useBlocker} from "react-router-dom";
import BeforeUnloadDialog from "components/BeforeUnloadDialog";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

export default function Plan(): React.ReactElement {
    const {currentProject, projectScans: scans, projectScansError} = useProject();
    const queryClient = useQueryClient();

    const [drawFeatures, setDrawFeatures] = React.useState<GeoJSON.FeatureCollection>({
        type: "FeatureCollection",
        features: [],
    });
    const [isShiftPressed, setIsShiftPressed] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<{severity: AlertColor; message: string}>();

    const data = React.useMemo(
        () =>
            scans
                ? createGeoJSONFeatureCollection(scans, () => colors.gray)
                : {type: "FeatureCollection", features: []},
        [scans],
    );

    const {data: savedFeatures, error: savedFeaturesError} = useQuery({
        queryKey: ["getSavedFeatures", currentProject?.id],
        queryFn: async () => {
            if (currentProject) {
                const res = await apiRequest({
                    path: `project/plan/${currentProject.id}/me`,
                });
                const collection: GeoJSON.FeatureCollection = res.data.collection?.features;

                return (
                    collection ?? {
                        type: "FeatureCollection",
                        features: [],
                    }
                );
            }
        },
        enabled: !!currentProject,
    });

    const openSnackbar = Boolean(snackbarMessage);

    const handleUploadGeoJSON = (event: Event): void => {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = event => {
                // process the geojson contents
                if (event.target?.result) {
                    let content = event.target?.result;
                    const decoder = new TextDecoder();
                    if (content instanceof ArrayBuffer) content = decoder.decode(content);
                    const geojson: GeoJSON.FeatureCollection = JSON.parse(content);
                    setDrawFeatures(prev => ({
                        ...prev,
                        features: [...prev.features, ...geojson.features],
                    }));
                }
            };
            reader.onerror = () => {
                setSnackbarMessage({
                    severity: "error",
                    message: "Could not parse the given file.",
                });
            };
        }
    };

    const hasUnsavedChanges = React.useMemo(() => {
        if (drawFeatures.features.length !== savedFeatures?.features.length) {
            return true;
        }

        // Compare each element
        for (let i = 0; i < drawFeatures.features.length; i++) {
            if (drawFeatures.features[i].id !== savedFeatures.features[i].id) {
                return true;
            } else if (!isEqual(drawFeatures.features[i].geometry, savedFeatures.features[i].geometry)) {
                return true;
            }
        }

        return false;
    }, [savedFeatures, drawFeatures]);

    React.useEffect(() => {
        if (savedFeatures) setDrawFeatures(savedFeatures);
    }, [savedFeatures]);

    const saveChangesMutation = useMutation({
        mutationFn: async () => {
            if (!currentProject) throw new Error("Could not retrieve current project");
            return await apiRequest({
                method: "post",
                path: `project/plan/${currentProject.id}/me`,
                data: {collection: drawFeatures},
            });
        },
        onSuccess: async () => {
            void queryClient.invalidateQueries({queryKey: ["getSavedFeatures", currentProject?.id]});
            setSnackbarMessage({
                severity: "success",
                message: "Changes saved successfully!",
            });
            if (blocker.state === "blocked" && blocker.proceed) blocker.proceed();
        },
        onError: async error => {
            setSnackbarMessage({
                severity: "error",
                message: `Could not save changes: ${error.message.includes("413") ? "" : error.message}`,
            });
            if (blocker.state === "blocked" && blocker.reset) blocker.reset();
        },
    });

    React.useEffect(() => {
        const onKeyDown = (e: KeyboardEvent): void => {
            if (e.key === "Shift") setIsShiftPressed(true);
        };

        const onKeyUp = (): void => {
            setIsShiftPressed(false);
        };

        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
            queryClient.setQueryData(["projectUsersData", currentProject?.id], null);
        };
    }, []);

    const blocker = useBlocker(({currentLocation, nextLocation}) => {
        return currentLocation.pathname !== nextLocation.pathname && hasUnsavedChanges;
    });

    React.useEffect(() => {
        const onKeyDown = (e: KeyboardEvent): void => {
            if (e.key === "Shift") setIsShiftPressed(true);
        };

        const onKeyUp = (): void => {
            setIsShiftPressed(false);
        };

        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
            queryClient.setQueryData(["projectUsersData", currentProject?.id], null);
        };
    }, []);

    return (
        <Box id="page-container" width="100%" height="100vh" position="relative">
            {projectScansError ?? savedFeaturesError ? (
                <LoadingError errorMessage={projectScansError ?? savedFeaturesError?.message} />
            ) : scans && savedFeatures ? (
                <>
                    <BeforeUnloadDialog
                        {...{blocker}}
                        onSave={() => {
                            saveChangesMutation.mutate();
                        }}
                        onLeave={() => {
                            if (blocker.proceed) blocker.proceed();
                        }}
                    />
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={5000}
                        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                        onClose={() => setSnackbarMessage(undefined)}>
                        <Alert
                            severity={snackbarMessage?.severity}
                            icon={snackbarMessage?.severity === "success" ? <CheckCircleOutline /> : <ErrorOutline />}>
                            {snackbarMessage?.message}
                        </Alert>
                    </Snackbar>
                    <DWMap
                        drawControl
                        satelliteControl
                        multiSelect
                        onMultiSelect={() => {
                            // idk
                        }}
                        dataId="id"
                        selectedFeature={undefined}
                        zoomOnClick={false}
                        data={data as GeoJSON.FeatureCollection}
                        {...{drawFeatures, setDrawFeatures}}
                    />
                    <ShiftControl isShiftPressed={isShiftPressed} top={8} left={24} />
                    <Stack
                        gap={2}
                        alignItems="center"
                        sx={{
                            position: "absolute",
                            bottom: 32,
                            right: 32,
                        }}>
                        <Button variant="contained" color="secondary" component="label" startIcon={<Upload />}>
                            Upload GeoJSON File
                            <VisuallyHiddenInput
                                type="file"
                                accept="application/geo+json"
                                onChange={handleUploadGeoJSON}
                            />
                        </Button>
                        <Tooltip title="Save plans">
                            <span>
                                <Fab
                                    color="primary"
                                    aria-label="save"
                                    sx={{
                                        p: 2,
                                        width: "auto",
                                        height: "auto",
                                    }}
                                    disabled={!hasUnsavedChanges}
                                    onClick={() => saveChangesMutation.mutate()}>
                                    <Save sx={{fontSize: "4rem"}} />
                                </Fab>
                            </span>
                        </Tooltip>
                    </Stack>
                </>
            ) : (
                <LoadingScreen />
            )}
        </Box>
    );
}
