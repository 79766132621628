// from Backend_Dev/src/models/stages.ts

export const dbStageNames = [
    "uploadSuccess",
    "preprocessing",
    "rasterization",
    "rescan_check",
    "pot_label",
    "ramp_label",
    "condition_label",
    "obstruction_label",
    "panel_label",
    "label_review",
    "auto_measurements",
    "measurements_fix",
    "measurements_fix_review",
    "report_gen",
    "passed",
    "idk",
    "idk_fix",
    "archive",
    "rescan",
    "tech",
    "label_review_fail",
    "rescan_review",
    "potree",
    "reraster",
] as const;

export type DBStageName = (typeof dbStageNames)[number];

export function isStageName(stageName: string): stageName is DBStageName {
    return (dbStageNames as unknown as string[]).includes(stageName.toLowerCase());
}
