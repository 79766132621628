import {Warning} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ClearWidthIcon from "assets/svg/ClearWidthIcon";
import CrossSlopeIcon from "assets/svg/CrossSlopeIcon";
import RunSlopeIcon from "assets/svg/RunSlopeIcon";
import React from "react";
import type {Panel, ViolationSeverity} from "react_ct/types";
import {colors} from "react_ct/theme";
import {darken} from "@mui/material/styles";
import {HtmlTooltip} from "./HtmlTooltip";

function WarningItem({
    prowagData,
    index,
    imgScale,
    violation,
    panel,
    imgRatio,
    areViolationsVisible,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    prowagData: any;
    index: number;
    imgScale: number;
    imgRatio: number;
    violation: string;
    panel: Panel;
    areViolationsVisible: string[];
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    const ICON_SIZE = 18;
    const ICON_RADIUS = ICON_SIZE / 2;

    const calcImageScale = 1 / imgScale;

    const itemId = `warning-${index}`;

    const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);

    const onOpen = (): void => {
        setOpenTooltip(true);
    };

    const onClose = (): void => {
        setOpenTooltip(false);
    };

    const onClick = (): void => {
        setSelectedCanvasItemId(itemId);
    };

    React.useEffect(() => {
        if (openTooltip && selectedCanvasItemId !== itemId) onClose();
    }, [selectedCanvasItemId]);

    const panelHasProwagException =
        prowagData?.ramps?.[0].landings?.includes(panel?.global_region_id) ??
        prowagData?.ramps?.[0].ramp_panels?.includes(panel?.global_region_id);

    return (
        <HtmlTooltip
            id={itemId}
            open={openTooltip}
            {...{onOpen, onClose, onClick}}
            disableHoverListener={selectedCanvasItemId === itemId}
            title={
                <>
                    <Stack direction="row" gap={1} alignItems="center">
                        <Warning
                            style={{
                                color:
                                    Object.values(panel.violations)[index] === "extreme"
                                        ? colors.black
                                        : Object.values(panel.violations)[index] === "severe"
                                        ? colors.red
                                        : Object.values(panel.violations)[index] === "moderate"
                                        ? colors.orange
                                        : darken(colors.yellow, 0.1),
                            }}
                        />
                        <Typography>
                            {violation.split("_")[0][0].toUpperCase() + violation.split("_")[0].substring(1)}{" "}
                            {violation.split("_")[1]}
                        </Typography>
                    </Stack>
                    <Typography textAlign="center">
                        {violation === "run_slope"
                            ? panel.runslope.toFixed(1) + "%"
                            : violation === "clear_width"
                            ? `${panel.width} in`
                            : panel.crossslope.toFixed(1) + "%"}
                    </Typography>
                </>
            }
            key={index}>
            <Box
                width={ICON_SIZE}
                height={ICON_SIZE}
                sx={{
                    cursor: "pointer",
                    position: "absolute",
                    top: `${
                        panel.centroid[1] * imgRatio -
                        ICON_RADIUS +
                        (index % 2 === 0 ? ICON_RADIUS : -ICON_RADIUS) * imgRatio * index
                    }px`,
                    left: `${
                        panel.centroid[0] * imgRatio -
                        ICON_RADIUS +
                        (index % 2 === 0 ? ICON_RADIUS : -ICON_RADIUS) * imgRatio * index
                    }px`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 11,
                    transform: `scale(${calcImageScale > imgRatio + 0.3 ? imgRatio + 0.3 : calcImageScale})`,
                }}>
                {violation === "run_slope" ? (
                    <RunSlopeIcon
                        violation={
                            panelHasProwagException && prowagData?.ramps?.[0].perpendicular_ramp_run_slope_exception
                                ? "ok"
                                : (Object.values(panel.violations)[index] as ViolationSeverity)
                        }
                        visible={areViolationsVisible.includes("run_slope")}
                    />
                ) : violation === "clear_width" ? (
                    <ClearWidthIcon
                        violation={Object.values(panel.violations)[index] as ViolationSeverity}
                        visible={areViolationsVisible.includes("clear_width")}
                    />
                ) : (
                    <CrossSlopeIcon
                        violation={
                            panelHasProwagException &&
                            (prowagData?.ramps?.[0].uncontrolled_ramp_cross_slope_exception ||
                                prowagData?.ramps?.[0].traffic_signal_ramp_cross_slope_exception)
                                ? "ok"
                                : (Object.values(panel.violations)[index] as ViolationSeverity)
                        }
                        visible={areViolationsVisible.includes("cross_slope")}
                    />
                )}
            </Box>
        </HtmlTooltip>
    );
}

export default function WarningOverlay({
    panelData,
    prowagData,
    imgRatio,
    imgScale,
    areViolationsVisible,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    panelData: Panel[];
    prowagData: any;
    imgRatio: number;
    imgScale: number;
    areViolationsVisible: string[];
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    return (
        <>
            {panelData.map(panel => {
                return Object.keys(panel.violations).map((violation, index) => (
                    <WarningItem
                        key={index}
                        {...{
                            prowagData,
                            imgRatio,
                            imgScale,
                            panel,
                            index,
                            violation,
                            areViolationsVisible,
                            selectedCanvasItemId,
                            setSelectedCanvasItemId,
                        }}
                    />
                ));
            })}
        </>
    );
}
