import React from "react";
import {Box, CircularProgress, Typography} from "@mui/material";

const LoadMap: React.FC = () => {
    // this element should appear on top of the filter dropwdown, it was not doing this before
    return (
        <Box
            position="absolute"
            height="100%"
            width="100%"
            zIndex={1000}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{
                backgroundColor: "#000000cc",
                backdropFilter: "blur(10px)",
            }}>
            <CircularProgress color="secondary" />
            <Typography variant="h4" color="secondary">
                Loading map data...
            </Typography>
        </Box>
    );
};

export default LoadMap;
