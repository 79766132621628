import React from "react";
import {Stack, Typography} from "@mui/material";
import CroppedImage from "../create/select/components/CroppedImage";
import type {ImplementProgramFeature} from "react_ct/types";

export function getRawFeature(
    rawFeatures: Array<GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>>,
    selectedFeature: ImplementProgramFeature | undefined,
): GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties> | undefined {
    if (!selectedFeature || !rawFeatures) return undefined;
    return rawFeatures.find(
        feature =>
            feature.geometry.coordinates[0] === selectedFeature.gpsCoordinate[0] &&
            feature.geometry.coordinates[1] === selectedFeature.gpsCoordinate[1],
    );
}

export function FeatureImageContainer({
    feature,
    imageContainerRef,
}: {
    feature: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties> | undefined;
    imageContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}): React.ReactElement {
    if (!feature) {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                sx={{backgroundColor: theme => theme.palette.grey[200]}}>
                <Typography variant="h4" color={theme => theme.palette.grey[500]}>
                    No image available
                </Typography>
            </Stack>
        );
    }

    if (feature.properties?.centroid && feature.properties?.labelled_media_link) {
        return (
            <CroppedImage
                url={feature.properties.labelled_media_link}
                centroid={feature.properties.centroid}
                containerRef={imageContainerRef}
            />
        );
    } else if (!feature.properties?.centroid && feature.properties?.cropped_media) {
        return (
            <img src={feature.properties?.cropped_media} alt={feature.properties?.address} width="100%" height="100%" />
        );
    } else {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                sx={{backgroundColor: theme => theme.palette.grey[200]}}>
                <Typography variant="h4" color={theme => theme.palette.grey[500]}>
                    No image available
                </Typography>
            </Stack>
        );
    }
}
