import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";

import Map, {
    Layer,
    type MapLayerMouseEvent,
    Source,
    type MapRef,
    type LngLatLike,
    type LngLatBoundsLike,
    type IControl,
} from "react-map-gl";
import {
    MAP_STYLE,
    SOURCE_IDS,
    type MapStyle,
    LAYER_IDS,
    type ShapeType,
    type PaintStyle,
    DRAW_STYLES,
} from "./constants";
import {createFilterArray, createSelectionFilterArray, type FilterLayer} from "./filterMapHelpers";
import Box from "@mui/material/Box/Box";
import {AnnotationsToggle, DrawOptions, ToggleSatelliteButton, useAnnotationsToggle} from "./MapComponents";
import mapboxgl from "mapbox-gl";
import {useTheme} from "@mui/material";
import {createLayerProps, sourceKeyToLayerShape} from "./drawMapHelpers";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

let MAPBOX_TOKEN: string | undefined;
try {
    MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_PK;
} catch (e) {
    // @ts-expect-error hack for using this component in storybook
    MAPBOX_TOKEN = import.meta.env.STORYBOOK_MAPBOX_PK;
}

if (!MAPBOX_TOKEN) {
    console.error("Mapbox token not found");
}

export interface DWMapProps {
    data: GeoJSON.FeatureCollection;
    /** the data selector id */
    dataId: string | string[];
    /** the id used to filter layers */
    filterLayers?: FilterLayer[];
    onMarkerClick?: (feature: GeoJSON.Feature) => void;
    onMultiSelect?: <T extends string>(ids: T[]) => void;
    filteredScanIds?: number[];
    // ** boolean to allow satellite layer toggle */
    satelliteControl?: boolean;
    satelliteControlSize?: "sm" | "lg";
    defaultSatelliteView?: "street" | "satellite";
    // ** boolean to allow annotation toggle */
    annotationControl?: boolean;
    drawControl?: boolean;
    drawFeatures?: GeoJSON.FeatureCollection;
    setDrawFeatures?: React.Dispatch<React.SetStateAction<GeoJSON.FeatureCollection>>;
    /** if the map should have a border radius */
    borderRadius?: boolean;
    /** the currently selected point */
    currentSelection?: number;
    /** if the map should allow multi select by holding shift */
    multiSelect?: boolean;
    /** the selected point */
    selectedFeature?: GeoJSON.Feature[] | GeoJSON.Feature | undefined;
    selectedDrawFeature?: GeoJSON.Feature | undefined;
    onDrawnFeatureClick?: (val: GeoJSON.Feature | undefined) => void;
    zoomOnClick?: boolean;
    cluster?: boolean;
    clusterRadius?: number;
    clusterProperties?: object;
}

const interactiveLayerIds = [
    String(LAYER_IDS.Point.regular),
    String(LAYER_IDS.LineString.regular),
    String(LAYER_IDS.Polygon.regular),
    String(LAYER_IDS.Symbol.regular),
];

export default function DWMap({
    data,
    dataId,
    filterLayers,
    satelliteControl,
    satelliteControlSize,
    defaultSatelliteView = "street",
    annotationControl,
    drawControl,
    drawFeatures,
    setDrawFeatures,
    borderRadius,
    onMarkerClick,
    multiSelect,
    onMultiSelect,
    selectedFeature,
    onDrawnFeatureClick,
    zoomOnClick = true,
    cluster = false,
}: DWMapProps): JSX.Element {
    const theme = useTheme();
    const annotationProps = useAnnotationsToggle();
    const {annotationOption} = annotationProps;

    const mapRef = React.useRef<MapRef | null>(null);
    const drawRef = React.useRef<MapboxDraw | null>(null);

    const [currentStyle, setCurrentStyle] = React.useState<MapStyle>(defaultSatelliteView);
    const [dragPan, setDragPan] = React.useState<boolean>(true);
    const [drawSelectedFeatures, setDrawSelectedFeatures] = React.useState<string[]>();
    const [drawCurrentMode, setDrawCurrentMode] = React.useState<MapboxDraw.DrawMode>("simple_select");

    const selectionFilterArray: mapboxgl.Expression = React.useMemo(() => {
        return createSelectionFilterArray(
            annotationControl ?? false,
            annotationOption,
            dataId,
            filterLayers,
            selectedFeature,
        );
    }, [annotationOption, selectedFeature, filterLayers]);

    const filterArray: mapboxgl.Expression = React.useMemo(() => {
        return createFilterArray(annotationControl ?? false, annotationOption, filterLayers);
    }, [filterLayers, annotationOption]);

    const onLoad = (): void => {
        if (mapRef.current) {
            if (drawFeatures) {
                const drawControlObject = new MapboxDraw({
                    displayControlsDefault: false,
                    styles: DRAW_STYLES,
                });
                drawRef.current = drawControlObject;
                mapRef.current.addControl(drawControlObject as unknown as IControl);
                drawRef.current.set(drawFeatures);
                mapRef.current.on("draw.selectionchange", (e: MapboxDraw.DrawSelectionChangeEvent) => {
                    // when a feature is selected, automatically go into direct_select mode to automatically adjust the vertices
                    if (e) {
                        const selectedFeatures: GeoJSON.Feature[] = e.features;
                        if (selectedFeatures) {
                            setDrawSelectedFeatures(selectedFeatures?.map(feature => String(feature.id)));
                        } else {
                            setDrawSelectedFeatures(undefined);
                            if (onDrawnFeatureClick) onDrawnFeatureClick(undefined);
                        }
                        if (drawRef.current && selectedFeatures.length === 1) {
                            setDrawCurrentMode("direct_select");
                            if (onDrawnFeatureClick) onDrawnFeatureClick(selectedFeatures[0]);
                        } else if (drawRef.current && selectedFeatures.length > 1) {
                            setDrawCurrentMode("simple_select");
                            if (onDrawnFeatureClick) onDrawnFeatureClick(undefined);
                        }
                    }
                });

                mapRef.current.on("draw.modechange", (e: MapboxDraw.DrawModeChangeEvent) => {
                    // change the state mode
                    setDrawCurrentMode(e.mode);
                });

                mapRef.current.on("draw.create", (e: MapboxDraw.DrawCreateEvent) => {
                    // automatically select a feature after it's created
                    const allFeatures = drawRef.current?.getAll().features;
                    const {features} = e;
                    if (features) {
                        setDrawSelectedFeatures(features.map((feature: GeoJSON.Feature) => String(feature.id)));
                    }
                    if (setDrawFeatures && allFeatures)
                        setDrawFeatures({
                            type: "FeatureCollection",
                            features: allFeatures,
                        });
                });

                mapRef.current.on("draw.update", () => {
                    const allFeatures = drawRef.current?.getAll().features;
                    if (setDrawFeatures && allFeatures) {
                        setDrawFeatures({
                            type: "FeatureCollection",
                            features: allFeatures,
                        });
                    }
                });

                mapRef.current.on("click", e => {
                    // if a feature is clicked, select it
                    if (drawRef.current) {
                        const currentMode = drawRef.current.getMode();
                        if (currentMode === "direct_select" || currentMode === "simple_select") {
                            const clickedFeatures = drawRef.current.getFeatureIdsAt(e.point).filter(feature => feature);
                            if (clickedFeatures.length > 0) {
                                drawRef.current.changeMode("direct_select", {
                                    featureId: clickedFeatures[0],
                                });
                                setDrawSelectedFeatures([String(drawRef.current.get(clickedFeatures[0])?.id)]);
                                onDrawnFeatureClick?.(drawRef.current.get(clickedFeatures[0]) as GeoJSON.Feature);
                            } else {
                                drawRef.current.changeMode("simple_select");
                                onDrawnFeatureClick?.(undefined);
                            }
                        }
                    }
                });
            }

            mapRef.current.on("mouseover", interactiveLayerIds, (e: MapLayerMouseEvent) => {
                e.target.getCanvas().style.cursor = "pointer";
            });

            mapRef.current.on("mouseout", interactiveLayerIds, (e: MapLayerMouseEvent) => {
                e.target.getCanvas().style.cursor = "";
            });

            mapRef.current.on("click", interactiveLayerIds, (e: MapLayerMouseEvent) => {
                const features = e.features;
                if (features && features.length > 0) {
                    const feature = features[0];
                    onMarkerClick?.(feature);
                }
            });

            mapRef.current.getCanvasContainer().addEventListener("keydown", (event: KeyboardEvent) => {
                if (multiSelect && onMultiSelect) {
                    if (event.key === "Shift") (event.target as HTMLDivElement).style.cursor = "crosshair";
                }
            });

            mapRef.current.getCanvasContainer().addEventListener("keyup", (event: KeyboardEvent) => {
                if ((event.target as HTMLDivElement).style.cursor === "crosshair")
                    (event.target as HTMLDivElement).style.cursor = "default";
            });

            mapRef.current?.getCanvasContainer().addEventListener("mousedown", (event: MouseEvent) => {
                if (multiSelect && onMultiSelect) {
                    // implement multiselect functionality

                    const canvas: HTMLElement = mapRef.current?.getCanvasContainer() as HTMLElement;
                    const mousePos = (e: MouseEvent): mapboxgl.Point => {
                        const rect = canvas?.getBoundingClientRect();
                        return new mapboxgl.Point(
                            e.clientX - rect.left - canvas?.clientLeft,
                            e.clientY - rect.top - canvas?.clientTop,
                        );
                    };

                    const start: mapboxgl.Point = mousePos(event);
                    let current;
                    let box: HTMLDivElement | null = null;

                    const finish = (bbox?: [mapboxgl.Point, mapboxgl.Point]): void => {
                        document.removeEventListener("mousemove", onMouseMove);
                        document.removeEventListener("mouseup", onMouseUp);
                        document.removeEventListener("keydown", onKeyDown);

                        if (box) {
                            box.parentNode?.removeChild(box);
                            box = null;
                        }

                        if (bbox) {
                            const features = mapRef.current?.queryRenderedFeatures(bbox, {});
                            // if (features) setSelectedFeatures(prev => [...prev, ...features]);
                            if (!drawFeatures && !drawRef.current) {
                                const ids = features?.map(
                                    feature => feature.properties?.[dataId as keyof GeoJSON.GeoJsonProperties],
                                );
                                onMultiSelect(ids as string[]);
                            } else {
                                const drawnFeatures = drawRef.current?.getAll();
                                const selectedDrawnFeatures = features?.filter(feature => {
                                    const matchingFeature = drawnFeatures?.features.find(
                                        drawnFeature => drawnFeature.id === feature.properties?.id,
                                    );
                                    return matchingFeature;
                                });
                                const selectedDrawnFeatureIds = selectedDrawnFeatures?.map(
                                    feature => feature.properties?.id,
                                );
                                setDrawSelectedFeatures(selectedDrawnFeatureIds as string[]);
                                setDrawCurrentMode("simple_select");
                            }
                        }

                        setDragPan(true);
                    };

                    const onMouseMove = (e: MouseEvent): void => {
                        current = mousePos(e);

                        if (!box) {
                            box = document.createElement("div");
                            box.classList.add("boxdraw");
                            canvas.appendChild(box);
                        }

                        // Adjust width and xy position of the box element ongoing
                        const minX = Math.min(start.x, current.x);
                        const maxX = Math.max(start.x, current.x);
                        const minY = Math.min(start.y, current.y);
                        const maxY = Math.max(start.y, current.y);

                        const pos = `translate(${minX}px, ${minY}px)`;
                        box.style.transform = pos;
                        box.style.width = `${maxX - minX}px`;
                        box.style.height = `${maxY - minY}px`;
                    };

                    const onMouseUp = (e: MouseEvent): void => {
                        finish([start, mousePos(e)]);
                    };

                    const onKeyDown = (e: KeyboardEvent): void => {
                        // If the ESC key is pressed
                        if (e.key === "27") finish();
                    };
                    // Continue the rest of the function if the shiftkey is pressed.
                    if (!(event.shiftKey && event.button === 0)) return;

                    // Disable default drag zooming when the shift key is held down.
                    setDragPan(false);

                    // Call functions for the following events
                    document.addEventListener("mousemove", onMouseMove);
                    document.addEventListener("mouseup", onMouseUp);
                    document.addEventListener("keydown", onKeyDown);
                }
            });
        }
    };

    React.useEffect(() => {
        if (zoomOnClick && selectedFeature) {
            if (Array.isArray(selectedFeature)) {
                const bounds = new mapboxgl.LngLatBounds();
                selectedFeature.forEach(feature => {
                    if (feature.geometry.type === "Point") {
                        const coordinates = feature.geometry.coordinates;

                        const pointBounds: [LngLatLike, LngLatLike] = [
                            [coordinates[0], coordinates[1]], // Southwest coordinates
                            [coordinates[0], coordinates[1]], // Northeast coordinates
                        ];
                        bounds.extend(pointBounds);
                    } else if (feature.geometry.type === "LineString") {
                        // extend bounds to fit linestring
                        const {coordinates} = feature.geometry;
                        const lineBounds = new mapboxgl.LngLatBounds(
                            [coordinates[0][0], coordinates[0][1]],
                            [coordinates[0][0], coordinates[0][1]],
                        );
                        for (const coord of coordinates) {
                            lineBounds.extend([coord[0], coord[1]]);
                        }
                        bounds.extend(lineBounds);
                    }
                });
                mapRef.current?.fitBounds(bounds as LngLatBoundsLike, {
                    padding: 50,
                });
            } else {
                if (selectedFeature.geometry.type === "Point") {
                    const coordinates = selectedFeature.geometry.coordinates;
                    const bounds: [LngLatLike, LngLatLike] = [
                        [coordinates[0], coordinates[1]], // Southwest coordinates
                        [coordinates[0], coordinates[1]], // Northeast coordinates
                    ];
                    mapRef.current?.fitBounds(bounds, {
                        padding: 0,
                    });
                } else if (selectedFeature.geometry.type === "LineString") {
                    // extend bounds to fit linestring
                    const {coordinates} = selectedFeature.geometry;
                    const lineBounds = new mapboxgl.LngLatBounds(
                        [coordinates[0][0], coordinates[0][1]],
                        [coordinates[0][0], coordinates[0][1]],
                    );
                    for (const coord of coordinates) {
                        lineBounds.extend([coord[0], coord[1]]);
                    }

                    mapRef.current?.fitBounds(lineBounds as LngLatBoundsLike, {
                        padding: 50,
                    });
                }
            }
        }
    }, [selectedFeature]);

    React.useEffect(() => {
        if (drawFeatures && drawRef.current) {
            drawRef.current.set(drawFeatures);
        }
    }, [drawFeatures]);

    const mapCenter = React.useMemo(() => {
        const bounds = new mapboxgl.LngLatBounds();
        if (!data.features.length && drawFeatures?.features.length) {
            // if there are no scan features, but there are drawn features present, use the drawn features
            drawFeatures.features
                .filter(feature => feature)
                .forEach(feature => {
                    if (feature.geometry.type === "Point")
                        bounds.extend(feature.geometry.coordinates as [number, number]);
                    else if (feature.geometry.type === "LineString") {
                        (feature.geometry.coordinates as Array<[number, number]>).forEach((coord: [number, number]) =>
                            bounds.extend(coord),
                        );
                    } else if (feature.geometry.type === "Polygon") {
                        (feature.geometry.coordinates as Array<Array<[number, number]>>).forEach(
                            (coordGroup: Array<[number, number]>) =>
                                coordGroup.forEach((coord: [number, number]) => bounds.extend(coord)),
                        );
                    }
                });
        } else {
            data.features
                .filter(feature => feature)
                .forEach(feature => {
                    if (feature.geometry.type === "Point") {
                        bounds.extend(feature.geometry.coordinates as [number, number]);
                    } else if (feature.geometry.type === "LineString") {
                        feature.geometry.coordinates.forEach((coord: GeoJSON.Position) =>
                            bounds.extend(coord as [number, number]),
                        );
                    }
                });
        }
        return bounds.isEmpty() ? new mapboxgl.LngLat(0, 0) : bounds.getCenter();
    }, [data]);

    React.useEffect(() => {
        if (drawRef.current) {
            switch (drawCurrentMode) {
                case "direct_select":
                    drawRef.current.changeMode(drawCurrentMode, {
                        featureId: drawSelectedFeatures?.[0] ?? "",
                    });
                    break;
                case "simple_select":
                    drawRef.current.changeMode(
                        drawCurrentMode,
                        drawSelectedFeatures
                            ? {
                                  featureIds: drawSelectedFeatures ?? [],
                              }
                            : undefined,
                    );
                    break;
                case "draw_line_string":
                    drawRef.current.changeMode(drawCurrentMode);
                    break;
                case "draw_polygon":
                    drawRef.current.changeMode(drawCurrentMode);
                    break;
                default:
                    drawRef.current.changeMode(drawCurrentMode);
                    break;
            }
        }
    }, [drawCurrentMode]);

    const getDataFeatures = (key: ShapeType, features: GeoJSON.Feature[]): GeoJSON.Feature[] => {
        const filteredFeatures = features.filter(feature => {
            if (key === "Symbol") {
                return feature.properties?.symbol_id;
            } else if (key === "Point") {
                return feature?.geometry.type === key && !feature?.properties?.symbol_id;
            } else {
                return feature && feature.geometry.type === key;
            }
        });

        return filteredFeatures;
    };

    return (
        <Box
            width="100%"
            height="100%"
            position="absolute"
            borderRadius={borderRadius ? theme.shape.borderRadius : 0}
            overflow="hidden">
            {satelliteControl && (
                <ToggleSatelliteButton
                    size={satelliteControlSize}
                    showSatellite={currentStyle === "satellite"}
                    onClick={() => setCurrentStyle(prev => (prev === "street" ? "satellite" : "street"))}
                />
            )}
            {annotationControl && <AnnotationsToggle {...annotationProps} />}
            {drawControl && (
                <DrawOptions
                    drawMode={drawCurrentMode}
                    changeDrawMode={setDrawCurrentMode}
                    drawer={drawRef.current}
                    drawSelectedFeatures={drawSelectedFeatures}
                    setDrawFeatures={setDrawFeatures}
                />
            )}
            <Map
                ref={mapRef}
                mapboxAccessToken={MAPBOX_TOKEN}
                mapStyle={MAP_STYLE[currentStyle]}
                initialViewState={{
                    longitude: mapCenter.lng,
                    latitude: mapCenter.lat,
                    zoom: data.features.length ?? drawFeatures?.features.length ? 13 : 0,
                }}
                doubleClickZoom={!drawFeatures}
                dragPan={dragPan}
                interactiveLayerIds={interactiveLayerIds}
                onLoad={onLoad}>
                {(Object.keys(SOURCE_IDS) as ShapeType[]).map((key: ShapeType) => (
                    <Source
                        key={SOURCE_IDS[key]}
                        type="geojson"
                        id={SOURCE_IDS[key]}
                        data={{
                            ...data,
                            features: getDataFeatures(key, data.features),
                        }}>
                        {(Object.keys(LAYER_IDS[key]) as PaintStyle[])
                            .filter((paintStyle: PaintStyle) => LAYER_IDS[key][paintStyle] !== null)
                            .map((paintStyle: PaintStyle) => {
                                const layerId = LAYER_IDS[key][paintStyle];
                                return layerId !== null ? (
                                    <Layer
                                        key={LAYER_IDS[key][paintStyle]}
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        source={SOURCE_IDS[key] as any}
                                        {...(createLayerProps(
                                            layerId,
                                            sourceKeyToLayerShape(key),
                                            key,
                                            paintStyle,
                                            ["get", "symbol_id"],
                                            annotationControl ?? false,
                                            annotationOption,
                                            selectionFilterArray,
                                            filterArray,
                                            currentStyle,
                                            !!drawFeatures,
                                        ) as
                                            | mapboxgl.CircleLayer
                                            | mapboxgl.SymbolLayer
                                            | mapboxgl.FillLayer
                                            | mapboxgl.LineLayer)}
                                    />
                                ) : (
                                    <></>
                                );
                            })}
                        {cluster && (
                            <Layer
                                id="cluster-count"
                                type="symbol"
                                filter={["any", ["has", "cluster"], ["has", "point_count"]]}
                                source={
                                    data.features.filter((feature: GeoJSON.Feature) => feature?.properties?.symbol_id)
                                        ? "map_Symbol"
                                        : "map_Point"
                                }
                                layout={{
                                    "text-field": ["get", "point_count_abbreviated"],
                                    "text-size": 12,
                                }}
                            />
                        )}
                    </Source>
                ))}
            </Map>
        </Box>
    );
}
