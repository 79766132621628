import {ChevronRight, FolderOpen} from "@mui/icons-material";
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    LinearProgress,
    Stack,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import LoadMap from "components/Map/LoadMap";
import {formatData, type Layer} from "components/Map/createDataHelpers";
import React from "react";
import {theme} from "react_ct/theme";
import icon_legend from "../../../assets/PopUp_Legend.png";
import {useProject} from "../../../contexts/ProjectContext";
import {useArray} from "../../../hooks/useArray";
import DownloadJSONs from "./components/Overview/DownloadJSONs";
import {FeatureLegend} from "./components/Overview/FeatureLegend";
import {FilterMenu} from "./components/Overview/FilterMenu";
import ImageCarousel from "./components/Overview/ImageCarousel";
import {getCensusKeyName, includedCensusKeys, type DownloadURL, legendItems} from "./components/Overview/helpers";
import DWMap from "components/Map/DWMap";
import {useQuery} from "@tanstack/react-query";
import {geojsonKeys, getCommunityGeojsonData, getGeojsonByType} from "queries/queries";

export const DataOverviewPage: React.FC = () => {
    const projectContext = useProject();

    const {currentProject: project, projectManualTags} = projectContext;

    const [downloadUrls, setDownloadUrls] = React.useState<DownloadURL>({
        geojson: {
            fileName: undefined,
            url: undefined,
        },
        indvData: {
            fileName: undefined,
            url: undefined,
        },
        obsData: {
            fileName: undefined,
            url: undefined,
        },
        communityData: {
            fileName: undefined,
            url: undefined,
        },
    });

    const [openDetailDrawer, setOpenDetailDrawer] = React.useState<boolean>(false);
    const [selectedFeature, setSelectedFeature] = React.useState<GeoJSON.Feature>();
    const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>(0);

    const [visibleScanLayers, setVisibleScanLayers] = useArray<Layer>(["Midblock Accessibility", "Curb Ramp"]);
    const [visiblePanelLayers, setVisiblePanelLayers] = useArray<Layer>([]);
    const [visibleManualLayers, setVisibleManualLayers] = useArray<Layer>([]);
    const [visiblePopulationLayers, setVisiblePopulationLayers] = useArray<Layer>([]);
    const [selectedValues, setSelectedValues] = React.useState<Array<{key: string; filterValues: number[]}>>([]);

    const visibleLayers = React.useMemo(() => {
        const combinedLayers = [
            ...visibleScanLayers,
            ...visiblePanelLayers,
            ...visibleManualLayers,
            ...visiblePopulationLayers,
        ];
        return combinedLayers;
    }, [visibleScanLayers, visiblePanelLayers, visibleManualLayers, visiblePopulationLayers]);

    const nameToKeyLayer = (name: string): string => {
        if (name.toLowerCase() === "midblock accessibility" || name.toLowerCase() === "curb ramp")
            return "midblock-accessibility-curb-ramp";
        return name.toLowerCase().replaceAll(" ", "-");
    };

    React.useEffect(() => {
        // when visible layers changes, update the selected filter values
        const visibleLayerKeys: string[] = [...new Set(visibleLayers.map(layer => nameToKeyLayer(layer)))];

        // remove values that are not in the list of visible layers
        const selectedValuesToKeep = selectedValues.filter(value => visibleLayerKeys.includes(value.key));

        visibleLayerKeys.forEach(layerKey => {
            // check if the layer's values already exist in the filter values array
            const selectedValueKeys = selectedValues.map(value => value.key);
            if (!selectedValueKeys.includes(layerKey)) {
                // if this is a new visible layer, add all of its values to the selected values array
                const legendItemKeys = legendItems.map(item => item.layerKey);
                if (legendItemKeys.includes(layerKey)) {
                    const legendItem = legendItems.find(item => item.layerKey === layerKey);
                    if (legendItem) {
                        const filterValues = legendItem?.values;
                        if (legendItem.values.length < legendItem?.gradient.length) {
                            const extraValue = legendItem.values[legendItem.values.length - 1] + 1;
                            filterValues.push(extraValue);
                        }
                        selectedValuesToKeep.push({key: layerKey, filterValues});
                    }
                } else {
                    selectedValuesToKeep.push({key: layerKey, filterValues: [0]});
                }
            }
        });

        setSelectedValues(selectedValuesToKeep);
    }, [visibleLayers]);

    const {data: geojsonData, isPending: isGeojsonPending} = useQuery({
        queryKey: geojsonKeys.featuresGeojson(project?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!project,
    });

    const {data: indvData} = useQuery({
        queryKey: geojsonKeys.individualFeaturesGeojson(project?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!project,
    });

    const {data: obsData} = useQuery({
        queryKey: geojsonKeys.obstructionsGeojson(project?.id),
        queryFn: async ({queryKey}) => {
            const response = await getGeojsonByType(...(queryKey as [number | undefined, string, string]));
            const geojsonData: GeoJSON.FeatureCollection = {
                ...response.geojsonData,
                features: response.geojsonData.features.map(feature => {
                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            layer_type: "Obstructions",
                            filter_key: 0,
                        },
                    };
                }),
            };
            return {
                ...response,
                geojsonData,
            };
        },
        enabled: !!project,
    });

    const {data: communityData} = useQuery({
        queryKey: geojsonKeys.communityGeojson(project?.id),
        queryFn: async () => await getCommunityGeojsonData(project),
        enabled: !!project,
    });

    const includedData = React.useMemo(
        () =>
            formatData(
                {
                    type: "FeatureCollection",
                    features: geojsonData
                        ? [
                              ...geojsonData.geojsonData.features,
                              ...(indvData?.geojsonData.features ?? []),
                              ...(obsData?.geojsonData.features ?? []),
                              ...(communityData?.geojsonData.features ?? []),
                          ]
                        : [],
                },
                projectManualTags,
            ),
        [geojsonData, indvData, obsData, communityData],
    );

    const featureLegendLayer = [
        ...visibleScanLayers.filter(layer => layer !== "Obstructions"),
        ...visiblePopulationLayers,
    ];

    const noDataAvailable = includedData.features.length === 0;

    const handleOpenDrawer = (): void => {
        setOpenDetailDrawer(true);
    };

    const handleCloseDrawer = (): void => {
        setOpenDetailDrawer(false);
        setSelectedFeature(undefined);
    };

    const onMarkerClick = (feature: GeoJSON.Feature): void => {
        if (!openDetailDrawer) handleOpenDrawer();
        setSelectedFeature(feature);
        setSelectedImageIndex(0);
    };

    React.useEffect(() => {
        if (!project) return;
        setDownloadUrls({
            geojson: {
                fileName: geojsonData ? `project${project?.id}.geojson` : undefined,
                url: geojsonData?.jsonUrl,
            },
            indvData: {
                fileName: indvData ? `project${project?.id}_manual.geojson` : undefined,
                url: indvData?.jsonUrl,
            },
            obsData: {
                fileName: obsData ? `project${project?.id}_obstructions.geojson` : undefined,
                url: obsData?.jsonUrl,
            },
            communityData: {
                fileName: communityData ? `project${project?.id}_community.geojson` : undefined,
                url: communityData?.jsonUrl,
            },
        });
    }, [geojsonData, indvData, obsData, communityData]);

    const filterKeyLayers = selectedValues
        .map(value => {
            const valueKeys = value.filterValues.map(filterVal => `${value.key}_${filterVal}`);
            return valueKeys;
        })
        .flat();

    return (
        <Box id="page-container" width="100%" height="100vh" position="relative">
            {isGeojsonPending ? (
                <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                    <Stack spacing={2}>
                        <LinearProgress color="inherit" />
                        <Typography variant="h3" color={theme => theme.palette.midnightBlue.light}>
                            Fetching map...
                        </Typography>
                    </Stack>
                </Box>
            ) : (
                <>
                    {isGeojsonPending && <LoadMap />}
                    {noDataAvailable && (
                        <Box
                            position="absolute"
                            zIndex={10}
                            width="100%"
                            height="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{backgroundColor: "#00000088"}}>
                            <Stack spacing={2} alignItems="center">
                                <FolderOpen
                                    sx={{
                                        color: "white",
                                        fontSize: "5rem",
                                    }}
                                />
                                <Typography variant="h3" color={"white"}>
                                    No data available
                                </Typography>
                            </Stack>
                        </Box>
                    )}
                    {!noDataAvailable && (
                        <Stack
                            direction="row"
                            spacing={4}
                            position="absolute"
                            zIndex={10}
                            mt={2}
                            ml={4}
                            alignItems="center">
                            <FilterMenu
                                visibleLayers={[
                                    visibleScanLayers,
                                    visiblePanelLayers,
                                    visibleManualLayers,
                                    visiblePopulationLayers,
                                ]}
                                setVisibleLayers={[
                                    setVisibleScanLayers,
                                    setVisiblePanelLayers,
                                    setVisibleManualLayers,
                                    setVisiblePopulationLayers,
                                ]}
                            />
                            <FeatureLegend
                                currentFeatures={featureLegendLayer}
                                {...{selectedValues, setSelectedValues}}
                            />
                            <DownloadJSONs downloadUrls={downloadUrls} />
                        </Stack>
                    )}
                    <DWMap
                        data={includedData}
                        dataId={["id", "scan_id", "GEOID"]}
                        filterLayers={[
                            {
                                id: "layer_type",
                                layers: visibleLayers,
                            },
                            {
                                id: "filter_key",
                                layers: filterKeyLayers,
                            },
                        ]}
                        onMarkerClick={onMarkerClick}
                        selectedFeature={selectedFeature}
                        satelliteControl={!noDataAvailable}
                        annotationControl={false}
                    />
                    <Drawer
                        anchor="right"
                        open={openDetailDrawer}
                        variant="persistent"
                        PaperProps={{
                            sx(theme) {
                                return {
                                    zIndex: 14,
                                    maxWidth: "25vw",
                                    height: "100%",
                                    backgroundColor: theme.palette.background.paper,
                                    color: theme.palette.text.primary,
                                    padding: theme.spacing(2),
                                };
                            },
                            elevation: 2,
                        }}>
                        {selectedFeature?.properties && (
                            <Stack
                                direction="row"
                                id="sticky-header"
                                justifyContent="space-between"
                                alignItems="center"
                                mb={2}>
                                <Typography variant="h5" color={theme.palette.text.primary}>
                                    {selectedFeature.properties?.layer_type}
                                </Typography>
                                <IconButton onClick={handleCloseDrawer}>
                                    <ChevronRight fontSize="large" />
                                </IconButton>
                            </Stack>
                        )}
                        {selectedFeature?.properties && selectedFeature.properties.layer_type !== "Census" && (
                            <React.Fragment>
                                {Object.entries(selectedFeature.properties).length > 0 && (
                                    <ImageCarousel
                                        imageList={Object.entries(selectedFeature.properties)
                                            .filter(
                                                (val, index) =>
                                                    Object.keys(selectedFeature.properties ?? {})[index].includes(
                                                        "media",
                                                    ) &&
                                                    Object.keys(selectedFeature.properties ?? {})[index] !==
                                                        "autoreport_media",
                                            )
                                            .sort((a, b) => a[0].localeCompare(b[0]))}
                                        selectedImageIndex={selectedImageIndex}
                                        setSelectedImageIndex={setSelectedImageIndex}
                                    />
                                )}
                                <Divider sx={{my: 4}} />
                            </React.Fragment>
                        )}
                        {selectedFeature?.geometry.type !== "Polygon" && (
                            <img
                                src={icon_legend}
                                alt="icon label guide"
                                width="100%"
                                height="auto"
                                style={{userSelect: "none", pointerEvents: "none"}}
                            />
                        )}
                        <Table sx={{mt: 4, maxWidth: "100%"}}>
                            <TableContainer>
                                {selectedFeature?.properties &&
                                    Object.keys(selectedFeature.properties)
                                        .filter(key => {
                                            if (
                                                selectedFeature.properties?.layer_type === "Census" &&
                                                includedCensusKeys.includes(key)
                                            )
                                                return true;
                                            else if (
                                                selectedFeature.properties?.layer_type !== "Census" &&
                                                !key.includes("media") &&
                                                key !== "color" &&
                                                key !== "raw_score" &&
                                                key !== "panels" &&
                                                key !== "regions" &&
                                                key !== "violations" &&
                                                key !== "report_path" &&
                                                key !== "labelled_img_path" &&
                                                key !== "raster_path"
                                            )
                                                return true;
                                            else return false;
                                        })
                                        .map(key => (
                                            <TableRow key={key}>
                                                <TableCell>
                                                    <Typography fontWeight="bold">
                                                        {selectedFeature.properties?.layer_type === "Census"
                                                            ? getCensusKeyName(key)
                                                            : key.replaceAll("_", " ")[0].toUpperCase() +
                                                              key.replaceAll("_", " ").slice(1)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>{selectedFeature.properties?.[key]}</TableCell>
                                            </TableRow>
                                        ))}
                            </TableContainer>
                        </Table>
                    </Drawer>
                </>
            )}
        </Box>
    );
};
