import {Warning} from "@mui/icons-material";
import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import {theme} from "react_ct/theme";

const LoadingError: React.FC<{
    errorMessage?: string;
}> = props => {
    const {errorMessage} = props;
    return (
        <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <Box textAlign="center">
                <Box>
                    <Typography variant="h1" color={theme.palette.primary.main}>
                        <Warning style={{fontSize: 80}} />
                    </Typography>
                </Box>
                <Stack spacing={1}>
                    <Typography component="h3" variant="h5" fontWeight="bold">
                        Oops! Something went wrong.
                    </Typography>
                    {errorMessage && <Typography>{errorMessage}</Typography>}
                </Stack>
            </Box>
        </Box>
    );
};

export default LoadingError;
