import React from "react";
import {HtmlTooltip} from "./HtmlTooltip";
import Stack from "@mui/material/Stack";
import {PriorityHigh} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {Triangle} from "assets/svg/Triangle";
import type {Region} from "react_ct/types";

function VerticalDisplacementItem({
    index,
    point,
    areDisplacementsVisible,
    imgRatio,
    imgScale,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    index: number;
    point: Region;
    areDisplacementsVisible: string[];
    imgRatio: number;
    imgScale: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    const ICON_SIZE = 24;
    const ICON_HALF = ICON_SIZE / 2;

    const transformRatio = 1 / imgScale > imgRatio + 0.3 ? imgRatio + 0.3 : 1 / imgScale;
    const itemId = `vd-${index}`;

    const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);

    const onOpen = (): void => {
        setOpenTooltip(true);
    };

    const onClose = (): void => {
        setOpenTooltip(false);
    };

    const onClick = (): void => {
        setSelectedCanvasItemId(itemId);
    };

    React.useEffect(() => {
        if (openTooltip && selectedCanvasItemId !== itemId) onClose();
    }, [selectedCanvasItemId]);

    return (
        <HtmlTooltip
            id={itemId}
            open={openTooltip}
            {...{onOpen, onClose, onClick}}
            disableHoverListener={selectedCanvasItemId === itemId}
            title={
                <>
                    <Stack direction="row" alignItems="center">
                        <PriorityHigh />
                        <Typography>{point.label}</Typography>
                    </Stack>
                </>
            }
            sx={{
                display:
                    !areDisplacementsVisible.includes("Deterioration") && point.label === "Deteriorated"
                        ? "none"
                        : !areDisplacementsVisible.includes(point.label)
                        ? "none"
                        : "block",
            }}>
            <svg
                width={ICON_SIZE}
                height={ICON_SIZE}
                style={{
                    position: "absolute",
                    display:
                        !areDisplacementsVisible.includes("Deterioration") && point.label === "Deteriorated"
                            ? "none"
                            : !areDisplacementsVisible.includes(point.label)
                            ? "none"
                            : "block",
                    zIndex: 10,
                    top: point.points[0][1] * imgRatio - ICON_HALF,
                    left: point.points[0][0] * imgRatio - ICON_HALF,
                    transform: `scale(${transformRatio})`,
                }}>
                <Triangle
                    points={`0 ${ICON_SIZE}, ${ICON_SIZE} ${ICON_SIZE}, ${ICON_HALF} 0`}
                    stroke="white"
                    fill={
                        point.label === "Deterioration" || point.label === "Deteriorated"
                            ? "rgb(255, 0, 0)"
                            : point.label === "Driveway"
                            ? "rgb(173, 216, 230)"
                            : point.label === "Horizontal"
                            ? "rgb(50, 205, 50)"
                            : point.label === "Utility Box"
                            ? "rgb(255, 215, 0)"
                            : point.label === "Overhang"
                            ? "rgb(255, 255, 255)"
                            : point.label === "Tree Hazard"
                            ? "rgb(230, 230, 250)"
                            : "rgb(0, 0, 255)"
                    }
                />
                <text
                    x={6}
                    y={18}
                    fill={point.label === "Driveway" || point.label === "Overhang" ? "#00000098" : "#ffffffee"}
                    fontSize={9}
                    fontWeight="bold">
                    {point.label === "Deterioration" || point.label === "Deteriorated"
                        ? "DE"
                        : point.label === "Driveway"
                        ? "DR"
                        : point.label === "Horizontal"
                        ? "HZ"
                        : point.label === "Utility Box"
                        ? "UB"
                        : point.label === "Overhang"
                        ? "OH"
                        : point.label === "Tree Hazard"
                        ? "TH"
                        : "VD"}
                </text>
            </svg>
        </HtmlTooltip>
    );
}

export default function VerticalDisplacementOverlay({
    pointData,
    areDisplacementsVisible,
    imgRatio,
    imgScale,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    pointData: Region[];
    areDisplacementsVisible: string[];
    imgRatio: number;
    imgScale: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    return (
        <>
            {pointData.map((point, index) => (
                <VerticalDisplacementItem
                    key={index}
                    {...{
                        index,
                        point,
                        areDisplacementsVisible,
                        imgRatio,
                        imgScale,
                        selectedCanvasItemId,
                        setSelectedCanvasItemId,
                    }}
                />
            ))}
        </>
    );
}
