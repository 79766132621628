import {z} from "zod";

// Record of projectId to {count, totalScanLength}
export const projectScanInfoSchema = z.record(
    z.object({
        count: z.number(),
        totalScanLength: z.number(),
    }),
);

export type ProjectScanInfo = z.infer<typeof projectScanInfoSchema>;

// Record of AnnotatorEmail to {Stage to {count, totalScanLength}}
export const projectAnnotatorScanInfoSchema = z.record(
    z.record(
        z.object({
            count: z.number(),
            totalScanLength: z.number(),
        }),
    ),
);

export type ProjectAnnotatorScanInfo = z.infer<typeof projectAnnotatorScanInfoSchema>;
