import {colors} from "react_ct/theme";

export const dataCategories: DataCategory[] = ["Inspection Summary", "Inspection Details", "Inspection Legend"];

export const adminDataCategories: DataCategory[] = [
    "Inspection Summary",
    "Inspection Details",
    "Inspection Legend",
    "Admin",
];

export const SCORE_COLORS = [
    colors.darkBlue,
    colors.darkRed,
    colors.lightRed,
    colors.orange,
    colors.yellow,
    colors.green,
];

export const tableContents: TableLegendItemProps[] = [
    {
        id: 5,
        score: 5,
        color: colors.green,
        description: "No significant barriers to access found",
    },
    {
        id: 4,
        score: 4,
        color: colors.yellow,
        description: "Minor barriers to access",
    },
    {
        id: 3,
        score: 3,
        color: colors.orange,
        description: "Moderate barriers to access found",
    },
    {
        id: 2,
        score: 2,
        color: colors.lightRed,
        description: "Major barriers to access found",
    },
    {
        id: 1,
        score: 1,
        color: colors.darkRed,
        description: "10%-25% deteriorated",
    },
    {
        id: 0,
        score: 0,
        color: colors.darkBlue,
        description: "More than 25% deteriorated",
    },
    {
        id: -1,
        color: colors.gray,
        description: "Stairway",
    },
];

export const stageOptions = [
    {name: "rescan_check"},
    {name: "pot_label"},
    {name: "panel_label"},
    {name: "ramp_label"},
    {name: "label_review"},
    {name: "condition_label"},
    {name: "rescan"},
    {name: "archive"},
    {name: "passed"},
    {name: "reportGen"},
    {name: "measurements_fix"},
    {name: "measurements_fix_review"},
    {name: "idk"},
    {name: "idk_fix"},
    {name: "false_scan"},
    {name: "rescan_review"},
    {name: "sam"},
    {name: "rasterization"},
    {name: "auto_measurements"},
];
