import {Card, CardHeader, CardContent, TextField, CardActions, Button} from "@mui/material";
import {type AxiosError} from "axios";
import {useDebounce} from "hooks/useDebounce";
import React, {type FormEventHandler, useEffect, useRef, useState} from "react";
import {apiRequest} from "react_ct/requests";
import {type AlertType} from "../Settings";

export const ChangePassword: React.FC<{
    setAlert: React.Dispatch<React.SetStateAction<AlertType | undefined>>;
}> = props => {
    const {setAlert} = props;
    const [currentPasswordError, setCurrentPasswordError] = useState<string>();
    const [newPasswordError, setNewPasswordError] = useState<string>();
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>();

    const currentPasswordRef = useRef<HTMLInputElement>(null);
    const newPasswordRef = useRef<HTMLInputElement>(null);

    const [confirmValue, setConfirmValue] = useState<string>("");
    const useDebouncedConfirmVal = useDebounce(confirmValue, 300);

    // API error is passed if the current password is wrong and if the new password is a bad format

    // if the confirm password field value is updated, check if the passwords match
    useEffect(() => {
        if (useDebouncedConfirmVal.length === 0) setConfirmPasswordError(undefined);
        if (useDebouncedConfirmVal.length > 0 && newPasswordRef.current?.value !== useDebouncedConfirmVal) {
            setConfirmPasswordError("Passwords do not match");
        } else {
            setConfirmPasswordError(undefined);
        }
    }, [useDebouncedConfirmVal]);

    const onSubmit: FormEventHandler = async event => {
        event.preventDefault();
        // clear password errors
        setCurrentPasswordError(undefined);
        setNewPasswordError(undefined);
        setConfirmPasswordError(undefined);
        // TODO: validate password

        // if the new passowrd field is empty
        if (newPasswordRef.current?.value.length === 0) {
            setNewPasswordError("Please enter a new password");
            return;
        }
        // if the new password is the same as the current password
        if (currentPasswordRef.current?.value === newPasswordRef.current?.value) {
            setNewPasswordError("New password cannot be the same as the current password");
            return;
        }
        // if the confirmed password field is empty, set an error
        if (useDebouncedConfirmVal.length === 0) {
            setConfirmPasswordError("Please confirm your password");
            return;
        }

        try {
            const res = await apiRequest({
                path: "auth/change-password",
                method: "POST",
                data: {
                    oldPassword: currentPasswordRef.current?.value,
                    newPassword: newPasswordRef.current?.value,
                },
            });

            if (res.status === 204) {
                setAlert({severity: "success", message: "Password updated"});
                currentPasswordRef.current!.value = "";
                newPasswordRef.current!.value = "";
                setConfirmValue("");
            }
        } catch (e) {
            const err: AxiosError = e as AxiosError;
            const error: AlertType = {
                severity: "error",
                message: "Change password error: ",
            };

            if (err.message.split(": ")[1] === "Request failed with status code 401") {
                error.message += "Incorrect password";
                setCurrentPasswordError("Incorrect password");
                setAlert(error);
            } else if (err.message.split(": ")[1] === "Request failed with status code 400") {
                error.message += "Invalid password";
                setNewPasswordError("Invalid password");
                setConfirmPasswordError("Invalid password");
                setAlert(error);
            } else {
                error.message += "An unexpected error occurred";
                setAlert(error);
            }
        }
    };

    return (
        <Card variant="outlined">
            <CardHeader title="Change password" />
            <form onSubmit={onSubmit}>
                <CardContent sx={{display: "flex", flexDirection: "column", gap: 2}}>
                    <TextField
                        error={Boolean(currentPasswordError)}
                        helperText={currentPasswordError}
                        inputRef={currentPasswordRef}
                        id="current-password"
                        label="Current password"
                        type="password"
                    />
                    <TextField
                        error={Boolean(newPasswordError)}
                        helperText={newPasswordError}
                        inputRef={newPasswordRef}
                        onFocus={() => setNewPasswordError(undefined)}
                        id="new-password"
                        label="New password"
                        type="password"
                    />
                    <TextField
                        error={Boolean(confirmPasswordError)}
                        helperText={confirmPasswordError}
                        id="confirm-password"
                        label="Confirm new password"
                        type="password"
                        value={confirmValue}
                        onChange={event => setConfirmValue(event.target.value)}
                    />
                </CardContent>
                <CardActions sx={{justifyContent: "flex-end"}}>
                    <Button
                        type="submit"
                        onClick={onSubmit}
                        onSubmit={onSubmit}
                        color="info"
                        variant="contained"
                        disableElevation>
                        Update password
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};
