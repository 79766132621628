import type {MapScan, CleanMapScan} from "react_ct/types";

type MapScanType_Deprecated = CleanMapScan | MapScan;
/**
 *
 * @param scan {MapScan} The scan to draw lines based on
 * @returns {GeoJSON.Feature | null} A GeoJSON feature for the scan, returns null if coordinates are not provided
 */
export const getLineFeature = (
    scan: MapScanType_Deprecated,
    getFeatureColor: (scan: MapScanType_Deprecated) => string,
    multilineCoordsId: "gpsMultiline" | "adjustedGpsMultiline",
): GeoJSON.Feature | null => {
    if (!scan.gpsMultiline) {
        return null;
    }

    const uniqueMultilineCoords = Array.from(new Set(scan.gpsMultiline));
    if (uniqueMultilineCoords.length === 1) {
        return null;
    }

    const uniqueAdjustedCoords = Array.from(new Set(scan.adjustedGpsMultiline));

    // scanLengthConfirmed will be undefined for MapScan type; cast to boolean
    const isLengthConfirmed = Boolean((scan as unknown as CleanMapScan).scanLengthConfirmed);

    return {
        type: "Feature",
        properties: {
            id: scan.id,
            scannerId: scan.scannerId,
            lat: scan.lat,
            lng: scan.lng,
            folderName: scan.folderName,
            createdAt: scan.createdAt,
            stage: scan.stage,
            scanLength: scan.scanLength,
            scanLengthConfirmed: isLengthConfirmed,
            gpsMultiline: uniqueMultilineCoords,
            adjustedGpsMultiline: uniqueAdjustedCoords,
            color: getFeatureColor(scan),
        },
        geometry: {
            type: "LineString",
            coordinates:
                multilineCoordsId === "adjustedGpsMultiline" &&
                scan[multilineCoordsId] !== undefined &&
                scan[multilineCoordsId] !== null &&
                scan[multilineCoordsId]?.length
                    ? uniqueAdjustedCoords
                    : uniqueMultilineCoords,
        },
    };
};

/**
 *
 * @param scan {MapScan} The scan to draw points based on
 * @returns {GeoJSON.Feature | null} A GeoJSON feature for the scan, returns null if coordinates are not provided
 */
export const getPointFeature = (
    scan: MapScanType_Deprecated,
    getFeatureColor: (scan: MapScanType_Deprecated) => string,
): GeoJSON.Feature | null => {
    if (!scan.lat || !scan.lng) {
        return null;
    }

    // scanLengthConfirmed will be undefined for MapScan type; cast to boolean
    const isLengthConfirmed = Boolean((scan as unknown as CleanMapScan).scanLengthConfirmed);

    return {
        type: "Feature",
        properties: {
            id: scan.id,
            scannerId: scan.scannerId,
            lat: scan.lat,
            lng: scan.lng,
            folderName: scan.folderName,
            createdAt: scan.createdAt,
            stage: scan.stage,
            scanLength: scan.scanLength,
            scanLengthConfirmed: isLengthConfirmed,
            gpsMultiline: scan.gpsMultiline,
            adjustedGpsMultiline: scan.adjustedGpsMultiline,
            color: getFeatureColor(scan),
        },
        geometry: {
            type: "Point",
            coordinates: [scan.lng, scan.lat],
        },
    };
};

export const createGeoJSONFeatureCollection = (
    scans: MapScanType_Deprecated[] | undefined,
    getFeatureColor: (scan: MapScanType_Deprecated) => string,
    multilineCoordsId: "gpsMultiline" | "adjustedGpsMultiline" = "gpsMultiline",
): GeoJSON.FeatureCollection | undefined => {
    if (scans) {
        return {
            type: "FeatureCollection",
            features: scans
                .map(scan => {
                    const multiline =
                        scan[multilineCoordsId] === undefined ? scan.gpsMultiline : scan[multilineCoordsId];
                    const uniqueMultilineCoords = Array.from(new Set(multiline));
                    return uniqueMultilineCoords.length > 1
                        ? (getLineFeature(
                              scan,
                              getFeatureColor,
                              scan[multilineCoordsId] === undefined ? "gpsMultiline" : multilineCoordsId,
                          ) as GeoJSON.Feature)
                        : (getPointFeature(scan, getFeatureColor) as GeoJSON.Feature);
                })
                .filter(feature => feature !== null),
        };
    } else return undefined;
};
