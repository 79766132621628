import axios from "axios";
import {unzipFile} from "helpers/utils";
import {apiGetGeojsonUrl, apiGetGeojsonZip, downloadFromS3} from "react_ct/requests";
import type {Project} from "react_ct/types";

export const userKeys = {
    users: ["users"],
    getUser: (userId: number) => [...userKeys.users, userId],
};

export const projectKeys = {
    projects: (userId: number | undefined) => ["projects", userId],
    getProject: (projectId: number, userId: number) => [...projectKeys.projects(userId), projectId],
    projectUsers: (projectId: number | undefined) => ["projectUsers", projectId],
    getProjectUser: (projectId: number | undefined, projectUserId: number | undefined) => [
        ...projectKeys.projectUsers(projectId),
        projectId,
        projectUserId,
    ],
    projectManualTags: (projectId: number | undefined) => ["manualTags", projectId],
    projectPrograms: (projectId: number | undefined) => ["programs", projectId],
    projectProgram: (projectId: number | undefined, programId: number | undefined | null) => [
        "program",
        projectId,
        programId,
    ],
};

export const geojsonKeys = {
    allGeojsonZips: (projectId: number | undefined) => ["geojsonZips", projectId],
    allGeojsonUrls: (projectId: number | undefined) => ["geojsonUrls", projectId],
    featuresGeojson: (projectId: number | undefined) => [projectId, "featuresGeojson", "geojsonUrl"],
    individualFeaturesGeojson: (projectId: number | undefined) => [
        projectId,
        "individualFeaturesGeojson",
        "individualFeaturesUrl",
    ],
    obstructionsGeojson: (projectId: number | undefined) => [projectId, "obstructionsGeojson", "obstructionsUrl"],
    communityGeojson: (projectId: number | undefined) => ["communityGeojson", projectId],
};

export const scanKeys = {
    scans: (projectId: number | undefined) => ["scans", projectId],
    scanRasterUrl: (scanId: number | undefined) => ["scanRasterUrl", scanId],
    scanRasterUrls: (scanIds: number[] | undefined) => ["scanRasterUrls", ...(scanIds ?? [])],
    scanAutoreportUrl: (scanId: number | undefined) => ["scanAutoreportUrl", scanId],
    scanImage: (scanId: number | undefined) => ["scanImage", scanId],
    scanImages: (scanIds: number[] | undefined) => ["scanImages", ...(scanIds ?? [])],
};

export async function getGeojsonByType(
    projectId: number | undefined,
    geojsonType: string,
    geojsonUrlKey: string,
): Promise<{
    geojsonData: GeoJSON.FeatureCollection;
    jsonUrl: string;
    lastModified: string | undefined;
}> {
    const geojsonUrl = await apiGetGeojsonZip(projectId as number, {
        geojson: geojsonType === "featuresGeojson",
        obstructions: geojsonType === "obstructionsGeojson",
        indvfeatures: geojsonType === "individualFeaturesGeojson",
    });
    const geojsonRes = await downloadFromS3(geojsonUrl[geojsonUrlKey as keyof typeof geojsonUrl] as string);
    const geojsonFileContents = await unzipFile(geojsonRes);
    const parsedJson = {
        ...geojsonFileContents,
        features: await Promise.all(
            geojsonFileContents.features.filter(
                feature =>
                    (feature.geometry as GeoJSON.Point | GeoJSON.LineString).coordinates[0] !== 0 &&
                    (feature.geometry as GeoJSON.Point | GeoJSON.LineString).coordinates[1] !== 0,
            ),
        ),
    };
    const jsonUrl = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(parsedJson) ?? "")}`;

    return {geojsonData: parsedJson, jsonUrl, lastModified: geojsonUrl.lastModified};
}

export async function getCommunityGeojsonData(
    currentProject: Project | undefined,
): Promise<{geojsonData: GeoJSON.FeatureCollection; jsonUrl: string} | undefined> {
    if (!currentProject) return;
    const geojsonUrl = await apiGetGeojsonUrl(currentProject?.id, "community");
    const data = await axios.get(geojsonUrl, {responseType: "json"});
    const geojsonData: GeoJSON.FeatureCollection = data.data;
    return {geojsonData, jsonUrl: geojsonUrl};
}
