import {colors} from "react_ct/theme";
import {ManualTag, type ViolationSeverity} from "react_ct/types";
import {layers} from "./constants";

export type Layer = (typeof layers)[number];

export interface MapData {
    type: Layer;
    collection: GeoJSON.FeatureCollection;
}

export const COLOR_MAP: Record<Layer, string | Record<ViolationSeverity, string>> = {
    "Tree Hazard": colors.green,
    Overhang: colors.darkBlue,
    "Utility Box": colors.darkGray,
    Vertical: colors.orange,
    "Trip Hazard": colors.orange,
    Horizontal: colors.purple,
    Driveway: colors.lightBlue,
    Hazard: colors.blue,
    Deterioration: "",
    "Cross Slope": {
        minor: colors.yellow,
        moderate: colors.orange,
        severe: colors.red,
        extreme: colors.darkRed,
    },
    "Run Slope": {
        minor: colors.yellow,
        moderate: colors.orange,
        severe: colors.red,
        extreme: colors.darkRed,
    },
    "Curb Ramp": {
        minor: colors.yellow,
        moderate: colors.orange,
        severe: colors.red,
        extreme: colors.darkRed,
    },
    "Midblock Accessibility": {
        minor: colors.yellow,
        moderate: colors.orange,
        severe: colors.red,
        extreme: colors.darkRed,
    },
};

/**
 * Creates an array of formatted data to display on the map
 * @param geojsonData GeoJSON data
 * @param indvFeatureData Individual feature data
 * @returns Formatted data for the map
 */
export const formatData = (
    geojsonData?: GeoJSON.FeatureCollection | null,
    manualTags?: ManualTag[],
): GeoJSON.FeatureCollection => {
    if (!geojsonData || !manualTags) {
        return {
            type: "FeatureCollection",
            features: [],
        };
    }
    const formattedData: GeoJSON.Feature[] = [];

    for (const layer of [...layers, ...manualTags.map(tag => tag.name)]) {
        const layerFeatures = getLayerFeatures(
            layer,
            geojsonData,
            manualTags.find(tag => tag.name === layer),
        );
        formattedData.push(...layerFeatures);
    }
    return {
        type: "FeatureCollection",
        features: formattedData,
    };
};

/**
 * Returns a feature collection for the specified layer
 * @param layer The layer to get a feature collection for
 * @param geojsonData GeoJSON data
 * @returns The feature collection for the layer
 */
const getLayerFeatures = (
    layer: Layer,
    geojsonData: GeoJSON.FeatureCollection,
    manualTag?: ManualTag,
): GeoJSON.Feature[] => {
    switch (layer) {
        case "Census":
            return communityCollection(geojsonData);
        case "Obstructions":
            return obstructionCollection(geojsonData);
        case "Cross Slope Panel":
            return crossSlopePanelCollection(geojsonData);
        case "Midblock Accessibility":
            return sidewalkCollection(geojsonData);
        case "Curb Ramp":
            return curbRampCollection(geojsonData);
        case "Clear Width":
            return clearWidthCollection(geojsonData);
        case "Cross Slope":
            return crossSlopeCollection(geojsonData);
        case "Run Slope":
            return runSlopeCollection(geojsonData);
        case "Deterioration":
            return deterioratedCollection(geojsonData);
        default:
            return indvFeatureCollection(layer, geojsonData, manualTag);
    }
};

const communityCollection = (geojsonData: GeoJSON.FeatureCollection): GeoJSON.Feature[] => {
    const layer = "Census";
    return geojsonData.features
        .filter(feature => feature.geometry.type === "Polygon")
        .map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                layer_type: layer,
                color:
                    feature.properties?.B01003_001E < 800
                        ? "#00066d"
                        : feature.properties?.B01003_001E < 1500
                        ? "#0042c5"
                        : feature.properties?.B01003_001E < 2000
                        ? "#13aeff"
                        : "#8defff",
                filter_key: `census_${
                    feature.properties?.B01003_001E < 800
                        ? 799
                        : feature.properties?.B01003_001E < 1500
                        ? 1499
                        : feature.properties?.B01003_001E < 2000
                        ? 1999
                        : 2001
                }`,
            },
        }));
};

/**
 * Creates a feature collection for the sidewalk accessibility layer
 * @param geojsonData The geojson data
 * @returns The feature collection for the sidewalk accessibility layer
 */
const sidewalkCollection = (geojsonData: GeoJSON.FeatureCollection): GeoJSON.Feature[] => {
    const layer = "Midblock Accessibility";
    return geojsonData.features
        .filter(feature => feature.properties?.id)
        .filter(feature => (feature.properties?.detectable_warning ?? "").toLowerCase() === "no dw necessary")
        .map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                layer_type: layer,
                color:
                    feature.properties?.accessibility_grade === 0
                        ? colors.black
                        : feature.properties?.accessibility_grade === 1
                        ? colors.red
                        : feature.properties?.accessibility_grade === 2
                        ? colors.red
                        : feature.properties?.accessibility_grade === 3
                        ? colors.orange
                        : feature.properties?.accessibility_grade === 4
                        ? colors.yellow
                        : colors.green,
                filter_key: `midblock-accessibility-curb-ramp_${feature.properties?.accessibility_grade as number}`,
            },
        }));
};

const crossSlopeCollection = (geojsonData: GeoJSON.FeatureCollection): GeoJSON.Feature[] => {
    return geojsonData.features
        .filter(feature => feature.properties?.id)
        .map(feature => {
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    layer_type: "Cross Slope",
                    color:
                        feature.properties?.cs_score < 5
                            ? colors.green
                            : feature.properties?.cs_score < 25
                            ? colors.yellow
                            : feature.properties?.cs_score < 50
                            ? colors.orange
                            : feature.properties?.cs_score < 100
                            ? colors.red
                            : colors.black,
                    filter_key: `cross-slope_${
                        feature.properties?.cs_score < 5
                            ? 5
                            : feature.properties?.cs_score < 25
                            ? 25
                            : feature.properties?.cs_score < 50
                            ? 50
                            : feature.properties?.cs_score < 100
                            ? 100
                            : 101
                    }`,
                },
            };
        });
};

const obstructionCollection = (geojsonData: GeoJSON.FeatureCollection): GeoJSON.Feature[] => {
    const obstructions = geojsonData.features
        .filter(
            feature => feature.properties?.layer_type === "Obstructions" && feature.properties?.type === "Obstruction",
        )
        .map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                symbol_id: `clear_width_${String(feature.properties?.max_severity)}`,
                color:
                    feature.properties?.max_severity === "extreme"
                        ? colors.black
                        : feature.properties?.max_severity === "severe"
                        ? colors.red
                        : feature.properties?.max_severity === "moderate"
                        ? colors.orange
                        : feature.properties?.max_severity === "minor"
                        ? colors.yellow
                        : colors.green,
                filter_key: "obstructions_0",
            },
        }));

    return obstructions;
};

const crossSlopePanelCollection = (geojsonData: GeoJSON.FeatureCollection): GeoJSON.Feature[] => {
    const crossSlope = geojsonData.features
        .filter(
            feature => feature.properties?.layer_type === "Obstructions" && feature.properties?.type === "Cross Slope",
        )
        .map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                layer_type: "Cross Slope Panel",
                symbol_id: `cross_slope_${String(feature.properties?.max_severity)}`,
                color:
                    feature.properties?.max_severity === "extreme"
                        ? colors.black
                        : feature.properties?.max_severity === "severe"
                        ? colors.red
                        : feature.properties?.max_severity === "moderate"
                        ? colors.orange
                        : feature.properties?.max_severity === "minor"
                        ? colors.yellow
                        : colors.green,
                filter_key: "cross-slope-panel_0",
            },
        }));

    return crossSlope;
};

/**
 * Creates a feature collection for the score based layers
 * @param layer The layer to create the collection for
 * @param geojsonData The geojson data
 * @returns The feature collection for the layer
 */
const runSlopeCollection = (geojsonData: GeoJSON.FeatureCollection): GeoJSON.Feature[] => {
    return geojsonData.features
        .filter(feature => feature.properties?.id)
        .map(feature => {
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    layer_type: "Run Slope",
                    color:
                        feature.properties?.avg_run_slope < 5
                            ? colors.green
                            : feature.properties?.avg_run_slope < 6
                            ? colors.yellow
                            : feature.properties?.avg_run_slope < 7
                            ? colors.orange
                            : feature.properties?.avg_run_slope < 8
                            ? colors.red
                            : colors.black,
                    filter_key: `run-slope_${
                        feature.properties?.avg_run_slope < 5
                            ? 5
                            : feature.properties?.avg_run_slope < 6
                            ? 6
                            : feature.properties?.avg_run_slope < 7
                            ? 7
                            : feature.properties?.avg_run_slope < 8
                            ? 8
                            : 9
                    }`,
                },
            };
        });
};

const clearWidthCollection = (geojsonData: GeoJSON.FeatureCollection): GeoJSON.Feature[] => {
    return geojsonData.features
        .filter(feature => feature.properties?.id)
        .map(feature => {
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    layer_type: "Clear Width",
                    color:
                        feature.properties?.avg_width < 36
                            ? colors.black
                            : feature.properties?.avg_width < 44
                            ? colors.red
                            : feature.properties?.avg_width < 50
                            ? colors.blue
                            : feature.properties?.avg_width < 62
                            ? colors.green
                            : colors.pink,
                    filter_key: `clear-width_${
                        feature.properties?.avg_width < 36
                            ? 36
                            : feature.properties?.avg_width < 44
                            ? 44
                            : feature.properties?.avg_width < 50
                            ? 50
                            : feature.properties?.avg_width < 62
                            ? 62
                            : 63
                    }`,
                },
            };
        });
};

/**
 * Creates a feature collection for the curb ramp layer
 * @param geojsonData The geojson data
 * @returns The feature collection for the curb ramp layer
 */
const curbRampCollection = (geojsonData: GeoJSON.FeatureCollection): GeoJSON.Feature[] => {
    const layer = "Curb Ramp";
    return geojsonData.features
        .filter(feature => feature.properties?.id)
        .filter(feature => feature.properties?.detectable_warning !== "No DW Necessary")
        .map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                layer_type: layer,
                color:
                    feature.properties?.accessibility_grade === 0
                        ? colors.black
                        : feature.properties?.accessibility_grade === 1
                        ? colors.darkRed
                        : feature.properties?.accessibility_grade === 2
                        ? colors.red
                        : feature.properties?.accessibility_grade === 3
                        ? colors.orange
                        : feature.properties?.accessibility_grade === 4
                        ? colors.yellow
                        : colors.green,
                filter_key: `midblock-accessibility-curb-ramp_${feature.properties?.accessibility_grade as number}`,
            },
        }));
};

const deterioratedCollection = (geojsonData: GeoJSON.FeatureCollection): GeoJSON.Feature[] => {
    return geojsonData.features
        .filter(feature => feature.properties?.id)
        .map(feature => {
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    layer_type: "Deterioration",
                    color:
                        feature.properties?.percent_deteriorated === 0
                            ? colors.green
                            : feature.properties?.percent_deteriorated < 10
                            ? colors.yellow
                            : feature.properties?.percent_deteriorated < 25
                            ? colors.orange
                            : feature.properties?.percent_deteriorated < 50
                            ? colors.red
                            : colors.black,
                    filter_key: `deterioration_${
                        feature.properties?.percent_deteriorated === 0
                            ? 0
                            : feature.properties?.percent_deteriorated < 10
                            ? 10
                            : feature.properties?.percent_deteriorated < 25
                            ? 25
                            : feature.properties?.percent_deteriorated < 50
                            ? 50
                            : 51
                    }`,
                },
            };
        });
};

/**
 * Creates a feature collection for the individual feature data
 * @param layer Layer information
 * @param indvFeatureData The individual feature data
 * @returns The feature collection for the layer
 */
const indvFeatureCollection = (
    layer: string,
    indvFeatureData: GeoJSON.FeatureCollection,
    manualTagData?: ManualTag,
): GeoJSON.Feature[] => {
    return indvFeatureData.features
        .filter(feature => feature.properties?.scan_id)
        .filter(feature => feature.properties?.type === layer)
        .map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                layer_type: layer,
                color: manualTagData ? manualTagData?.color : "#000",
                filter_key: `${layer.toLowerCase().replaceAll(" ", "-")}_0`,
            },
        }));
};
