import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import DWMap from "components/Map/DWMap";
import {useProject} from "contexts/ProjectContext";
import {ViolationSeverity} from "react_ct/types";
import GeoJSONLoadingError from "components/LoadingError";
import LoadingScreen from "components/LoadingScreen";
import {colors} from "react_ct/theme";
import ImplementFilterMenu from "./components/FilterMenu";
import {type WorkOrderGeojsonFeature} from "./components/constants";
import ImplementSidebar from "./components/ImplementSidebar";
import ReviewProgramModal from "./components/ReviewProgramModal";
import {useLocation, useParams} from "react-router-dom";
import {type ImplementStrategy, implementStrategyArray} from "../../types";
import {ShiftControl} from "components/Map/MapComponents";
import {useQuery} from "@tanstack/react-query";
import {geojsonKeys, getGeojsonByType} from "queries/queries";
import {apiReverseGeocode} from "react_ct/requests";
import {type FilterLayer} from "components/Map/filterMapHelpers";

export default function ProgramSelectFeatures(): JSX.Element {
    const projectContext = useProject();
    const {state, pathname} = useLocation();
    const parsedProgramType = pathname.split("/")[pathname.split("/").length - 1].replace(" ", "");
    const programType = parsedProgramType === "triphazard" ? "trip_hazard" : parsedProgramType;
    const params = useParams();
    const implementType: ImplementStrategy = params.type as ImplementStrategy;
    const features: GeoJSON.Feature[] = state?.features ?? [];
    const [programName, setProgramName] = React.useState<string | undefined>(state?.programName ?? undefined);
    const {currentProject: project, projectManualTags} = projectContext;
    const [selectedFeatures, setSelectedFeatures] = React.useState<GeoJSON.Feature[]>(features ?? []);
    const [isShiftPressed, setIsShiftPressed] = React.useState(false);
    const [filteredSeverities, setFilteredSeverities] = React.useState<string[]>(
        (Object.keys(ViolationSeverity) as Array<keyof typeof ViolationSeverity>).map(key => ViolationSeverity[key]),
    );
    const [filteredTags, setFilteredTags] = React.useState<string[]>(projectManualTags?.map(tag => tag.name) ?? []);
    const [openModal, setOpenModal] = React.useState(false);

    React.useEffect(() => {
        if (!filteredTags.length && projectManualTags) setFilteredTags(projectManualTags.map(tag => tag.name));
    }, [projectManualTags]);

    const {data: rawFeaturesData, error: featuresError} = useQuery({
        queryKey: [
            ...(implementType === "vegetation"
                ? geojsonKeys.obstructionsGeojson(project?.id)
                : geojsonKeys.individualFeaturesGeojson(project?.id)),
            implementType,
        ],
        enabled: !!project,
        queryFn: async ({queryKey}) => {
            return await getGeojsonByType(...(queryKey as [number | undefined, string, string]));
        },
    });

    const featuresData: GeoJSON.FeatureCollection | undefined = React.useMemo(() => {
        if (!rawFeaturesData) return;
        if (implementType === "vegetation") {
            return {
                ...rawFeaturesData?.geojsonData,
                features: rawFeaturesData?.geojsonData.features
                    .filter((feature: GeoJSON.Feature) => feature.properties?.type === "Obstruction")
                    .filter(
                        (feature: GeoJSON.Feature, index: number, arr: GeoJSON.Feature[]) =>
                            arr.findIndex(f => f.properties?.scan_id === feature.properties?.scan_id) === index,
                    )
                    .map((feature: GeoJSON.Feature) => {
                        return {
                            ...feature,
                            properties: {
                                ...feature.properties,
                                color:
                                    feature.properties?.max_severity === "extreme"
                                        ? colors.black
                                        : feature.properties?.max_severity === "severe"
                                        ? colors.darkRed
                                        : feature.properties?.max_severity === "moderate"
                                        ? colors.orange
                                        : feature.properties?.max_severity === "minor"
                                        ? colors.yellow
                                        : colors.green,
                                symbol_id: `clear_width${`_${String(feature.properties?.max_severity)}` ?? ""}`,
                            },
                        };
                    }),
            };
        } else {
            return {
                ...rawFeaturesData?.geojsonData,
                features: rawFeaturesData?.geojsonData.features
                    .filter(
                        (feature: GeoJSON.Feature, index: number, arr: GeoJSON.Feature[]) =>
                            arr.findIndex(f => f.properties?.scan_id === feature.properties?.scan_id) === index,
                    )
                    .map((feature: GeoJSON.Feature) => ({
                        ...feature,
                        properties: {
                            ...feature.properties,
                            color:
                                projectManualTags?.find(tag => feature.properties?.type === tag.name)?.color ??
                                colors.orange,
                        },
                    })),
            };
        }
    }, [implementType, rawFeaturesData]);

    const addSelectedFeature = async (featureToAdd: GeoJSON.Feature): Promise<void> => {
        let featureWithAddress: GeoJSON.Feature;
        try {
            featureWithAddress = {
                ...featureToAdd,
                properties: {
                    ...featureToAdd.properties,
                    address: await apiReverseGeocode({
                        x: (featureToAdd.geometry as GeoJSON.Point).coordinates[0],
                        y: (featureToAdd.geometry as GeoJSON.Point).coordinates[1],
                    }),
                },
            };
        } catch (error) {
            console.error(error);
            featureWithAddress = featureToAdd;
        } finally {
            setSelectedFeatures(prev => [...prev, featureWithAddress]);
        }
    };

    const removeSelectedFeature = (featureToRemove: GeoJSON.Feature): void => {
        setSelectedFeatures(prev => prev.filter(f => f.properties?.scan_id !== featureToRemove.properties?.scan_id));
    };

    const latestSelectedFeatures = React.useRef<GeoJSON.Feature[]>(selectedFeatures);

    const isFeatureSelected = (clickedFeature: GeoJSON.Feature): boolean => {
        return latestSelectedFeatures.current.some(
            selectedFeature => clickedFeature.properties?.scan_id === selectedFeature.properties?.scan_id,
        );
    };

    const handleMarkerClick = (clickedFeature: GeoJSON.Feature): void => {
        if (clickedFeature.properties && featuresData) {
            // find the selected feature in the geojson
            const feature = featuresData?.features?.find(
                (feature: GeoJSON.Feature) => feature.properties?.scan_id === clickedFeature.properties?.scan_id,
            );
            const existsInSelection = isFeatureSelected(feature as WorkOrderGeojsonFeature);

            if (feature && !existsInSelection) void addSelectedFeature(feature);
            else if (feature && existsInSelection) {
                removeSelectedFeature(feature);
            }
        }
    };

    const handleMultiSelect = (ids: string[]): void => {
        // if there are selected features, find them in the geojson
        if (ids) {
            const features = featuresData?.features?.filter((feature: GeoJSON.Feature) =>
                ids.includes(feature.properties?.scan_id),
            );
            features?.forEach((feature: GeoJSON.Feature) => {
                const existsInSelection = isFeatureSelected(feature as WorkOrderGeojsonFeature);
                if (!existsInSelection) {
                    void addSelectedFeature(feature);
                }
            });
        }
    };

    const handleKeyDown = (e: KeyboardEvent): void => {
        if (e.key === "Shift") {
            setIsShiftPressed(true);
        }
    };

    const handleKeyUp = (e: KeyboardEvent): void => {
        if (e.key === "Shift") {
            setIsShiftPressed(false);
        }
    };

    React.useEffect(() => {
        latestSelectedFeatures.current = selectedFeatures;
    }, [selectedFeatures]);

    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        document.addEventListener("keyup", handleKeyUp);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    const createFilterLayers = (implementType: string): FilterLayer[] | undefined => {
        switch (implementType) {
            case "vegetation":
                return [{id: "max_severity", layers: filteredSeverities}];
            case "triphazard":
                return [{id: "type", layers: filteredTags}];
            default:
                return undefined;
        }
    };
    return (
        <Box id="page-container" sx={{height: "100vh", width: "100%", position: "relative"}}>
            {!featuresError && implementStrategyArray.includes(implementType) && featuresData?.features.length ? (
                <>
                    <Stack direction="row" sx={{height: "100%"}}>
                        <Box width="67%" height="100%" position="relative">
                            {featuresData?.features.length === 0 && (
                                <Box
                                    width="100%"
                                    height="100%"
                                    zIndex={10}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        backgroundColor: "#000000bb",
                                        backdropFilter: "blur(5px)",
                                    }}>
                                    <Typography color="white" fontSize="1.5rem">
                                        No hazards found
                                    </Typography>
                                </Box>
                            )}
                            <ShiftControl isShiftPressed={isShiftPressed} top={8} right={8} />
                            {implementType === "vegetation" && (
                                <ImplementFilterMenu
                                    filters={filteredSeverities}
                                    setFilters={setFilteredSeverities}
                                    valueLabels={[
                                        {value: "minor", label: '42" - 46"'},
                                        {value: "moderate", label: '36" - 42"'},
                                        {value: "severe", label: '24" - 36"'},
                                        {value: "extreme", label: '0" - 24"'},
                                    ]}
                                />
                            )}
                            {implementType === "triphazard" && projectManualTags && (
                                <ImplementFilterMenu
                                    filters={filteredTags}
                                    setFilters={setFilteredTags}
                                    valueLabels={projectManualTags.map(tag => ({
                                        value: tag.name,
                                        label: tag.name
                                            .split(" ")
                                            .map(word => `${word[0].toUpperCase()}${word.slice(1)}`)
                                            .join(" "),
                                        color: tag.color,
                                    }))}
                                />
                            )}
                            <DWMap
                                multiSelect
                                data={featuresData}
                                dataId="scan_id"
                                filterLayers={createFilterLayers(implementType)}
                                selectedFeature={selectedFeatures}
                                onMarkerClick={handleMarkerClick}
                                onMultiSelect={handleMultiSelect}
                                zoomOnClick={false}
                                cluster
                                clusterProperties={{
                                    minor: ["+", ["case", ["==", "max_severity", "minor"], 1, 0]],
                                    moderate: ["+", ["case", ["==", "max_severity", "moderate"], 1, 0]],
                                    severe: ["+", ["case", ["==", "max_severity", "severe"], 1, 0]],
                                    extreme: ["+", ["case", ["==", "max_severity", "extreme"], 1, 0]],
                                }}
                            />
                        </Box>
                        <ImplementSidebar {...{selectedFeatures, setOpenModal, setSelectedFeatures}} />
                    </Stack>
                    <ReviewProgramModal
                        programNameValue={programName}
                        programId={state?.programId ?? undefined}
                        {...{openModal, setOpenModal, selectedFeatures, setProgramName, programType}}
                    />
                </>
            ) : featuresError ?? !project ? (
                <GeoJSONLoadingError
                    errorMessage={featuresError?.message ?? "No project for this user could be found"}
                />
            ) : !implementStrategyArray.includes(implementType) ? (
                <GeoJSONLoadingError errorMessage={"Could not load strategy"} />
            ) : (
                <LoadingScreen />
            )}
        </Box>
    );
}
