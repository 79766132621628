import React from "react";
import type {Panel, Region} from "react_ct/types";
import {HtmlTooltip} from "./HtmlTooltip";
import Stack from "@mui/material/Stack";
import {InfoOutlined, PriorityHigh} from "@mui/icons-material";
import {colors} from "react_ct/theme";
import Typography from "@mui/material/Typography";
import {rgbToHex} from "@mui/material/styles";
import {Divider} from "@mui/material";

function PolygonShape({
    index,
    polygon,
    prowagData,
    matchingPanel,
    arePolygonsVisible,
    imgRatio,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    index: number;
    polygon: Region;
    prowagData: any;
    matchingPanel: Panel | undefined;
    arePolygonsVisible: string[];
    imgRatio: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);

    const itemId = `polygon-${index}`;

    const onClick = (): void => {
        setSelectedCanvasItemId(itemId);
    };

    const onOpen = (): void => {
        setOpenTooltip(true);
    };

    const onClose = (): void => {
        setOpenTooltip(false);
    };

    React.useEffect(() => {
        if (openTooltip && selectedCanvasItemId !== itemId) onClose();
    }, [selectedCanvasItemId]);

    const panelHasProwagException =
        prowagData?.ramps?.[0].landings?.includes(matchingPanel?.global_region_id) ??
        prowagData?.ramps?.[0].ramp_panels?.includes(matchingPanel?.global_region_id);

    return (
        <HtmlTooltip
            id={itemId}
            placement="top"
            open={openTooltip}
            {...{onClick, onOpen, onClose}}
            disableHoverListener={selectedCanvasItemId === itemId}
            title={
                <>
                    <Stack direction="row" alignItems="center">
                        {polygon.label !== "Panel" && (
                            <PriorityHigh
                                sx={{
                                    color:
                                        polygon.label === "Cracked" ||
                                        polygon.label === "Gravel" ||
                                        polygon.label === "Deterioration" ||
                                        polygon.label === "Deteriorated"
                                            ? colors.red
                                            : polygon.label === "Brick"
                                            ? colors.blue
                                            : polygon.label === "Obstruction"
                                            ? colors.orange
                                            : polygon.label === "Stairs" || polygon.label === "Gutter_Steps"
                                            ? colors.green
                                            : polygon.label === "Asphalt"
                                            ? colors.purple
                                            : colors.lightGray,
                                }}
                            />
                        )}
                        <Stack>
                            <Typography fontWeight="bold">
                                {polygon.label === "Gutter_Steps" ? "Steps" : polygon.label}
                            </Typography>
                            {matchingPanel && (
                                <Stack>
                                    <Typography>
                                        Cross slope:{" "}
                                        {matchingPanel.crossslope < 0
                                            ? "N/A"
                                            : `${matchingPanel.crossslope.toFixed(1)}%`}
                                    </Typography>
                                    <Typography>
                                        Run slope:{" "}
                                        {matchingPanel.runslope < 0 ? "N/A" : `${matchingPanel.runslope.toFixed(1)}%`}
                                    </Typography>
                                    <Typography>
                                        Clear width:{" "}
                                        {matchingPanel.width < 0 ? "N/A" : `${matchingPanel.width.toFixed(1)} in`}
                                    </Typography>
                                </Stack>
                            )}
                            {panelHasProwagException && (
                                <Stack gap={1.5} mt={1} boxSizing="border-box">
                                    <Divider />
                                    {prowagData?.ramps?.[0].perpendicular_ramp_run_slope_exception && (
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <InfoOutlined color="success" />{" "}
                                            <Typography fontSize="0.8rem" lineHeight="0.8rem">
                                                Perpendicular ramp run slope exception
                                            </Typography>
                                        </Stack>
                                    )}
                                    {prowagData?.ramps?.[0].uncontrolled_ramp_cross_slope_exception && (
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <InfoOutlined color="success" />
                                            <Typography fontSize="0.8rem" lineHeight="0.8rem">
                                                Uncontrolled ramp cross slope exception
                                            </Typography>
                                        </Stack>
                                    )}
                                    {prowagData?.ramps?.[0].traffic_signal_ramp_cross_slope_exception && (
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <InfoOutlined color="success" />
                                            <Typography fontSize="0.8rem" lineHeight="0.8rem">
                                                Traffic signal ramp cross slope exception
                                            </Typography>
                                        </Stack>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </>
            }
            sx={{
                display:
                    !arePolygonsVisible.includes("Deterioration") && polygon.label === "Deteriorated"
                        ? "none"
                        : !arePolygonsVisible.includes("Stairs") && polygon.label === "Gutter_Steps"
                        ? "none"
                        : !arePolygonsVisible.includes(polygon.label)
                        ? "none"
                        : "block",
            }}>
            <path
                onMouseEnter={event => {
                    const color = rgbToHex((event.target as SVGPathElement).style.fill);
                    (event.target as SVGPathElement).style.fill = color.substring(0, color.length - 2) + "66";
                }}
                onMouseLeave={event => {
                    const color = rgbToHex((event.target as SVGPathElement).style.fill);
                    (event.target as SVGPathElement).style.fill =
                        color.substring(0, color.length - 2) + (polygon.label !== "Panel" ? "33" : "00");
                }}
                stroke={
                    polygon.label === "Cracked" ||
                    polygon.label === "Gravel" ||
                    polygon.label === "Deterioration" ||
                    polygon.label === "Deteriorated"
                        ? colors.red
                        : polygon.label === "Brick"
                        ? colors.blue
                        : polygon.label === "Obstruction"
                        ? colors.orange
                        : polygon.label === "Stairs" || polygon.label === "Gutter_Steps"
                        ? colors.green
                        : polygon.label === "Asphalt"
                        ? colors.purple
                        : colors.lightGray
                }
                strokeWidth={`${
                    polygon.label !== "Panel" ? 4 * imgRatio : panelHasProwagException ? 8 * imgRatio : 2 * imgRatio
                }px`}
                d={[
                    `M ${polygon.points[0][0] * imgRatio} ${polygon.points[0][1] * imgRatio}`,
                    ...polygon.points.map(point => `L${point[0] * imgRatio} ${point[1] * imgRatio}`),
                    `L ${polygon.points[0][0] * imgRatio} ${polygon.points[0][1] * imgRatio}`,
                ].join(" ")}
                style={{
                    position: "relative",
                    display:
                        !arePolygonsVisible.includes("Deterioration") && polygon.label === "Deteriorated"
                            ? "none"
                            : !arePolygonsVisible.includes("Stairs") && polygon.label === "Gutter_Steps"
                            ? "none"
                            : !arePolygonsVisible.includes(polygon.label)
                            ? "none"
                            : "block",
                    zIndex: polygon.label === "Panel" ? 10 : 9,
                    cursor: "pointer",
                    fill:
                        polygon.label === "Cracked" ||
                        polygon.label === "Gravel" ||
                        polygon.label === "Deterioration" ||
                        polygon.label === "Deteriorated"
                            ? colors.red + "33"
                            : polygon.label === "Brick"
                            ? colors.blue + "33"
                            : polygon.label === "Obstruction"
                            ? colors.orange + "33"
                            : polygon.label === "Stairs" || polygon.label === "Gutter_Steps"
                            ? colors.green + "33"
                            : polygon.label === "Asphalt"
                            ? colors.purple + "33"
                            : "#ffffff00",
                    transition: "fill 0.1s",
                }}
            />
        </HtmlTooltip>
    );
}

const comparePanelAndRegionCentroids = (a: Panel, b: Region, epsilon = 1): boolean => {
    if (b.centroid === undefined || b.centroid === null || !a.centroid) {
        return false;
    }
    return Math.abs(a.centroid[0] - b.centroid[0]) < epsilon && Math.abs(a.centroid[1] - b.centroid[1]) < epsilon;
};

export default function PolygonOverlay({
    polygonData,
    panelData,
    prowagData,
    arePolygonsVisible,
    imgRatio,
    selectedCanvasItemId,
    setSelectedCanvasItemId,
}: {
    polygonData: Region[];
    panelData: Panel[];
    prowagData: any;
    arePolygonsVisible: string[];
    imgRatio: number;
    selectedCanvasItemId: string | null;
    setSelectedCanvasItemId: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactElement {
    return (
        <svg width="100%" height="100%" style={{position: "absolute"}}>
            {polygonData.map((polygon: Region, index) => {
                const matchingPanel: Panel | undefined = panelData.find((panel: Panel) => {
                    return polygon.centroid && comparePanelAndRegionCentroids(panel, polygon);
                });
                return (
                    <PolygonShape
                        key={index}
                        {...{
                            index,
                            prowagData,
                            matchingPanel,
                            polygon,
                            arePolygonsVisible,
                            imgRatio,
                            selectedCanvasItemId,
                            setSelectedCanvasItemId,
                        }}
                    />
                );
            })}
        </svg>
    );
}
