import {Add, Cancel, Delete, Edit, Save, SentimentVeryDissatisfied} from "@mui/icons-material";
import {
    Alert,
    type AlertColor,
    Box,
    Button,
    ClickAwayListener,
    IconButton,
    Paper,
    Snackbar,
    Stack,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import BeforeUnloadDialog from "components/BeforeUnloadDialog";
import {useProject} from "contexts/ProjectContext";
import {type ArrayActions, useArray} from "hooks/useArray";
import {useDebounce} from "hooks/useDebounce";
import React from "react";
import {HexColorPicker} from "react-colorful";
import {useBlocker} from "react-router-dom";
import {apiRequest} from "react_ct/requests";
import {type ManualTag, type ManualTagSQL} from "react_ct/types";

function ProjectTagRow({
    tag,
    tagData,
    setTagData,
    editState,
}: {
    tag: ManualTag;
    tagData: ManualTag[];
    setTagData: ArrayActions<ManualTag>;
    editState: boolean;
}): React.ReactElement {
    const theme = useTheme();

    const index = tagData.findIndex(t => t.id === tag.id);
    const [color, setColor] = React.useState<string>(tag.color);
    const [tagNameValue, setTagNameValue] = React.useState<string>(tag.name);
    const [tagDefinitionValue, setTagDefinitionValue] = React.useState<string>(tag.definition);
    const [openColorPicker, setOpenColorPicker] = React.useState<boolean>(false);

    const debouncedColor = useDebounce(color, 300);
    const debouncedNameVal = useDebounce(tagNameValue, 300);
    const debouncedDefinitionVal = useDebounce(tagDefinitionValue, 300);

    React.useEffect(() => {
        if (
            index > -1 &&
            debouncedColor &&
            debouncedColor.length > 0 &&
            debouncedNameVal.length > 0 &&
            debouncedDefinitionVal.length > 0
        ) {
            setTagData.replace(index, {
                ...tagData[index],
                color: debouncedColor,
                name: debouncedNameVal,
                definition: debouncedDefinitionVal,
            });
        }
    }, [debouncedColor, debouncedNameVal, debouncedDefinitionVal]);

    React.useEffect(() => {
        setColor(tag.color);
        setTagNameValue(tag.name);
        setTagDefinitionValue(tag.definition);
    }, [editState]);

    return (
        <Paper variant="outlined" sx={{px: 2, py: 1, borderRadius: 50}}>
            <Stack direction="row" alignItems="center" spacing={8}>
                <Stack direction="row" alignItems="center" spacing={2} flexShrink={0} flexBasis="25%">
                    <ClickAwayListener
                        onClickAway={() => {
                            if (openColorPicker) setOpenColorPicker(false);
                        }}>
                        <Box position="relative" flexBasis="20px" flexShrink={0}>
                            {editState && (
                                <Paper
                                    variant="outlined"
                                    sx={theme => ({
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "10px",
                                        height: "10px",
                                        top: -10,
                                        left: 15,
                                        borderRadius: 50,
                                        padding: 1,
                                        backgroundColor: theme.palette.grey[100],
                                    })}
                                    elevation={0}>
                                    <Edit sx={{fontSize: "12px"}} />
                                </Paper>
                            )}
                            <Box
                                width="20px"
                                height="20px"
                                borderRadius="50%"
                                bgcolor={color}
                                onClick={editState ? () => setOpenColorPicker(prev => !prev) : undefined}
                                border={editState ? `3px solid ${theme.palette.grey[200]}` : undefined}
                                boxSizing="content-box"
                                sx={{
                                    cursor: editState ? "pointer" : "auto",
                                }}
                            />
                            {editState && (
                                <Box
                                    position="absolute"
                                    visibility={openColorPicker ? "visible" : "hidden"}
                                    zIndex={5}
                                    left="-80px"
                                    bottom="-210px">
                                    <HexColorPicker color={color} onChange={setColor} />
                                </Box>
                            )}
                        </Box>
                    </ClickAwayListener>
                    {editState ? (
                        <TextField
                            variant="outlined"
                            aria-label="tag name"
                            placeholder={tag.name}
                            value={tagNameValue}
                            onChange={event => setTagNameValue(event.target.value)}
                        />
                    ) : (
                        <Typography fontWeight="bold" fontSize="1.2rem" alignSelf="flex-start">
                            {tagNameValue}
                        </Typography>
                    )}
                </Stack>

                {editState ? (
                    <TextField
                        aria-label="tag definition"
                        placeholder={tag.definition}
                        style={{flexGrow: 1}}
                        value={tagDefinitionValue}
                        multiline
                        onChange={event => setTagDefinitionValue(event.target.value)}
                    />
                ) : (
                    <Typography flexGrow={1}>{tagDefinitionValue}</Typography>
                )}
                <Box flexBasis="24px">
                    {editState && tag.editable && (
                        <IconButton
                            color="error"
                            sx={{padding: 0}}
                            onClick={() => {
                                const index = tagData.findIndex(t => t.id === tag.id);
                                if (index > -1) setTagData.remove(index);
                            }}>
                            <Delete />
                        </IconButton>
                    )}
                </Box>
            </Stack>
        </Paper>
    );
}

const normalizedRGBToHexString = (red: number, green: number, blue: number): string => {
    const redVal = Math.round(red * 255);
    const greenVal = Math.round(green * 255);
    const blueVal = Math.round(blue * 255);

    const redHex = redVal.toString(16);
    const greenHex = greenVal.toString(16);
    const blueHex = blueVal.toString(16);

    const formatHex = (hex: string): string => {
        if (hex.length === 1) return `0${hex}`;
        else return hex;
    };

    const hexString = `#${formatHex(redHex)}${formatHex(greenHex)}${formatHex(blueHex)}`;

    return hexString;
};

export const convertTagColorToHex = (tag: ManualTagSQL): ManualTag => {
    const hexColor = normalizedRGBToHexString(tag.red_normalized, tag.green_normalized, tag.blue_normalized);
    return {
        id: tag.id,
        name: tag.name,
        definition: tag.definition,
        editable: tag.editable,
        color: hexColor,
    };
};

export function ProjectTags(): React.ReactElement {
    const qc = useQueryClient();
    const theme = useTheme();
    const projectContext = useProject();
    const {
        currentProject: project,
        projectManualTags: manualTags,
        projectManualTagsError: manualTagsError,
    } = projectContext;
    const [editState, setEditState] = React.useState<boolean>(false);
    const [tempTagData, setTempTagData] = useArray<ManualTag>([]);
    const tagData = React.useMemo(() => tempTagData, [editState, tempTagData]);
    const [alertMsg, setAlertMsg] = React.useState<{severity: AlertColor; msg: string} | null>(null);

    const blocker = useBlocker(({currentLocation, nextLocation}) => {
        return editState && currentLocation.pathname !== nextLocation.pathname;
    });

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
        if (reason === "clickaway") {
            return;
        }

        setAlertMsg(null);
    };

    React.useEffect(() => {
        if (manualTags) {
            setTempTagData.overwrite(manualTags);
        }
    }, [manualTags]);

    const saveTagsMutation = useMutation({
        mutationKey: ["saveManualTags"],
        mutationFn: async () => {
            if (project && tempTagData) {
                const manualTags: ManualTagSQL[] = tempTagData.map(tag => {
                    const red = parseInt(tag.color.slice(1, 3), 16) / 255;
                    const green = parseInt(tag.color.slice(3, 5), 16) / 255;
                    const blue = parseInt(tag.color.slice(5, 7), 16) / 255;
                    return {
                        id: tag.id,
                        name: tag.name,
                        definition: tag.definition,
                        red_normalized: red,
                        green_normalized: green,
                        blue_normalized: blue,
                        editable: tag.editable,
                    };
                });
                return await apiRequest({
                    path: `project/config/${project.id}/manualTags`,
                    method: "post",
                    data: {manualTags},
                });
            }
        },
        onSuccess: () => {
            void qc.invalidateQueries({queryKey: ["getManualTags", project?.id]});
            setEditState(false);
            setAlertMsg({severity: "success", msg: "Changes saved"});
            if (blocker.state === "blocked" && blocker.proceed) blocker.proceed();
        },
        onError: error => {
            setAlertMsg({severity: "error", msg: `Failed to save changes: ${error.message}`});
            if (blocker.state === "blocked" && blocker.reset) blocker.reset();
        },
    });

    return (
        <>
            <BeforeUnloadDialog
                {...{blocker}}
                onSave={() => {
                    saveTagsMutation.mutate();
                }}
                onLeave={() => {
                    if (blocker.proceed) blocker.proceed();
                }}
            />
            <Box p={3} height="100vh" width="67%" margin="auto">
                <Typography variant="h2" component="h2">
                    Manual Tags
                </Typography>
                <Typography component="p" color={theme.palette.midnightBlue.light}>
                    Manual tagging is a feature that allows data collectors to mark sidewalk features that are not
                    automatically collected by DeepWalk. Set the manual tags for your project below.
                </Typography>
                {tagData.length > 0 && tempTagData.length > 0 && !manualTagsError && (
                    <Box width="100%" margin="auto" id="page-body" mt={6}>
                        <Stack margin="auto" mt={2} spacing={2}>
                            <Stack direction="row" spacing={4} alignItems="baseline">
                                <Box flexBasis="20px" />
                                <Box flexBasis="25%" flexShrink={0}>
                                    <Typography
                                        fontWeight="bold"
                                        textAlign="left"
                                        color={theme.palette.midnightBlue.light}>
                                        Tag Name
                                    </Typography>
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography
                                        fontWeight="bold"
                                        textAlign="left"
                                        color={theme.palette.midnightBlue.light}>
                                        Tag Definition
                                    </Typography>
                                </Box>
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    {editState && (
                                        <Button
                                            color="error"
                                            variant="outlined"
                                            sx={{borderRadius: 50}}
                                            onClick={() => {
                                                setEditState(false);
                                            }}>
                                            <Stack direction="row" gap={1}>
                                                <Cancel />
                                                <Typography color="error">Discard changes</Typography>
                                            </Stack>
                                        </Button>
                                    )}
                                    <Button
                                        variant={editState ? "contained" : "outlined"}
                                        sx={{borderRadius: 50, transition: "width 0.2s ease-in-out"}}
                                        onClick={
                                            editState ? () => saveTagsMutation.mutate() : () => setEditState(true)
                                        }>
                                        <Stack direction="row" gap={2}>
                                            {editState ? <Save /> : <Edit />}
                                            {editState && (
                                                <Typography color={editState ? "secondary" : "primary"}>
                                                    Save changes
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Button>
                                </Stack>
                            </Stack>
                            {(editState ? tempTagData : tagData).map(tag => (
                                <Box key={tag.id} position="relative">
                                    <ProjectTagRow
                                        tag={tag}
                                        tagData={editState ? tempTagData : tagData}
                                        setTagData={setTempTagData}
                                        editState={editState}
                                    />
                                </Box>
                            ))}
                        </Stack>
                        {editState && (
                            <Box
                                border={`1px solid ${theme.palette.grey[300]}`}
                                width="100%"
                                borderRadius={50}
                                mt={6}
                                px={2}
                                py={1}
                                onClick={() => {
                                    setTempTagData.push({
                                        id: tagData[tagData.length - 1].id + 1,
                                        name: "New tag name",
                                        definition: "Give your tag a definition",
                                        color: "#ff0000",
                                        editable: true,
                                    });
                                }}
                                sx={{cursor: "pointer"}}>
                                <Stack direction="row" spacing={1} justifyContent="center">
                                    <Add sx={theme => ({color: theme.palette.grey[600]})} />
                                    <Typography color={theme.palette.grey[600]}>Add</Typography>
                                </Stack>
                            </Box>
                        )}
                    </Box>
                )}

                {manualTagsError && (
                    <Box
                        position="relative"
                        mt={6}
                        border={`1px solid ${theme.palette.grey[300]}`}
                        bgcolor={theme.palette.grey.A100}
                        borderRadius={theme.shape.borderRadius / 2}
                        height="25vh"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap={1}>
                        <SentimentVeryDissatisfied sx={{color: theme.palette.grey[400], fontSize: "2rem"}} />
                        <Typography variant="h2" textAlign="center" color={theme.palette.grey[500]}>
                            {manualTagsError}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                autoHideDuration={3000}
                open={!!alertMsg}
                onClose={handleClose}>
                <Alert severity={alertMsg?.severity}>{alertMsg?.msg}</Alert>
            </Snackbar>
        </>
    );
}
