import {
    Box,
    Button,
    Chip,
    Grid,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    chipClasses,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import LoadingError from "components/LoadingError";
import LoadingScreen from "components/LoadingScreen";
import React from "react";
import type {IndividualPanelFeature, ManualTag, ViolationSeverity} from "react_ct/types";
import {GridItem} from "../Home";
import DWMap from "components/Map/DWMap";
import {colors} from "react_ct/theme";
import {toProperCase} from "helpers/utils";
import {PieChart} from "@mui/x-charts";
import {ShiftControl} from "components/Map/MapComponents";
import {Close} from "@mui/icons-material";
import {useQuery} from "@tanstack/react-query";
import {useProject} from "contexts/ProjectContext";
import {apiGetGeojsonUrl, downloadFromS3} from "react_ct/requests";

const severityColors = [colors.green, colors.yellow, colors.orange, colors.darkRed, colors.black];

function TitlePill({
    color,
    children,
    flexBasis = 67,
}: {
    color: string;
    children: string | JSX.Element | JSX.Element[];
    flexBasis?: number;
}): React.ReactElement {
    return (
        <Typography
            textAlign="center"
            sx={{
                flexBasis: `${flexBasis}%`,
                backgroundColor: color + "33",
                px: 1,
                py: 0.25,
                borderRadius: "50px",
            }}>
            {children}
        </Typography>
    );
}

function crossSlopeToSeverity(val: number): string {
    if (val < 2.5) {
        return "ok";
    } else if (val >= 2.5 && val < 3) {
        return "minor";
    } else if (val >= 3 && val < 4) {
        return "moderate";
    } else if (val >= 4 && val < 6) {
        return "severe";
    } else {
        return "extreme";
    }
}

function runSlopeToSeverity(val: string | undefined): string {
    return val ?? "ok";
}

const clearWidthBounds = ["> 46in", "42 - 46in", "36 - 42in", "24 - 36in", "< 24in"];

function clearWidthToSeverity(val: number): string {
    if (val > 46) return "ok";
    else if (val <= 46 && val > 42) return "minor";
    else if (val <= 42 && val > 36) return "moderate";
    else if (val <= 36 && val > 24) return "severe";
    else return "extreme";
}

type IndividualPanelProperties = IndividualPanelFeature & {id: number; color: string};

export default function PointsDashboard({
    indvGeojson,
    indvDataError,
    projectManualTags,
}: {
    indvGeojson: GeoJSON.FeatureCollection | undefined;
    indvDataError: Error | null;
    projectManualTags: ManualTag[] | undefined;
}): React.ReactElement {
    const {currentProject} = useProject();

    const {data: panelData, error: panelDataError} = useQuery({
        queryKey: ["indvPanelData", currentProject?.id],
        enabled: !!currentProject && currentProject.id === 39,
        queryFn: async () => {
            const geojsonLink = await apiGetGeojsonUrl(currentProject?.id ?? 0, "individual-panel");
            const response = await downloadFromS3(geojsonLink);
            return await response.json();
        },
    });

    const panelDataGeojson: GeoJSON.FeatureCollection<GeoJSON.Point, IndividualPanelProperties> = panelData;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

    const [selectedChart, setSelectedChart] = React.useState<string>("cross_slope");
    const [selectedMapView, setSelectedMapView] = React.useState<string>("cross_slope");
    const [selectedFeatures, setSelectedFeatures] = React.useState<
        Array<GeoJSON.Feature<GeoJSON.Point, IndividualPanelProperties>>
    >([]);
    const [isShiftPressed, setIsShiftPressed] = React.useState(false);

    const coloredMapData: GeoJSON.FeatureCollection<GeoJSON.Point, IndividualPanelProperties> = React.useMemo(() => {
        if (!panelDataGeojson)
            return {
                type: "FeatureCollection",
                features: [],
            };

        return {
            ...panelDataGeojson,
            features: panelDataGeojson.features.map((feature, index) => {
                return {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        id: index,
                        symbol_id: `${selectedMapView}_${
                            selectedMapView === "cross_slope"
                                ? crossSlopeToSeverity(feature.properties.crossslope)
                                : selectedMapView === "run_slope"
                                ? runSlopeToSeverity(feature.properties.violations.run_slope)
                                : clearWidthToSeverity(feature.properties.width)
                        }`,
                    },
                };
            }),
        };
    }, [panelDataGeojson, selectedMapView]);

    const handleKeyDown = (e: KeyboardEvent): void => {
        if (e.key === "Shift") {
            setIsShiftPressed(true);
        }
    };

    const handleKeyUp = (e: KeyboardEvent): void => {
        if (e.key === "Shift") {
            setIsShiftPressed(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        document.addEventListener("keyup", handleKeyUp);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    const handleChartChange = (event: React.MouseEvent<HTMLElement>, newChart: string | null): void => {
        if (newChart) setSelectedChart(newChart);
    };

    const filteredMapData = React.useMemo(() => {
        return selectedFeatures.length === 0
            ? coloredMapData
            : {
                  ...coloredMapData,
                  features: selectedFeatures,
              };
    }, [coloredMapData, selectedFeatures]);

    const manualTagCount: Record<string, number> | undefined = React.useMemo(() => {
        if (!indvGeojson) return undefined;
        const initial: Record<string, number> = {};
        return indvGeojson.features.reduce((accumulated, current) => {
            const tag: string = current.properties?.type;
            if (accumulated[tag]) accumulated[tag] = accumulated[tag] + 1;
            else accumulated[tag] = 1;
            return accumulated;
        }, initial);
    }, [indvGeojson]);

    const crossSlopeCount: Record<string, number> = React.useMemo(() => {
        const initial: Record<string, number> = {};
        return filteredMapData.features.reduce((accumulated, current) => {
            if (current.properties.crossslope < 2.5) {
                if (accumulated["< 2.5%"]) {
                    accumulated["< 2.5%"]++;
                } else {
                    accumulated["< 2.5%"] = 1;
                }
            } else if (current.properties.crossslope >= 2.5 && current.properties.crossslope < 3) {
                if (accumulated["2.5 - 3%"]) {
                    accumulated["2.5 - 3%"]++;
                } else {
                    accumulated["2.5 - 3%"] = 1;
                }
            } else if (current.properties.crossslope >= 3 && current.properties.crossslope < 4) {
                if (accumulated["3 - 4%"]) {
                    accumulated["3 - 4%"]++;
                } else {
                    accumulated["3 - 4%"] = 1;
                }
            } else if (current.properties.crossslope >= 4 && current.properties.crossslope < 6) {
                if (accumulated["4 - 6%"]) {
                    accumulated["4 - 6%"]++;
                } else {
                    accumulated["4 - 6%"] = 1;
                }
            } else {
                if (accumulated["> 6%"]) {
                    accumulated["> 6%"]++;
                } else {
                    accumulated["> 6%"] = 1;
                }
            }

            return accumulated;
        }, initial);
    }, [filteredMapData]);

    const runSlopeCount: Record<string, number> = React.useMemo(() => {
        const initial: Record<string, number> = {};
        return filteredMapData.features.reduce((accumulated, current) => {
            if (current.properties.violations.run_slope) {
                if (accumulated[current.properties.violations.run_slope as ViolationSeverity]) {
                    accumulated[current.properties.violations.run_slope] += 1;
                } else {
                    accumulated[current.properties.violations.run_slope as ViolationSeverity] = 1;
                }
            } else {
                if (accumulated["No violations"]) {
                    accumulated["No violations"]++;
                } else {
                    accumulated["No violations"] = 1;
                }
            }

            return accumulated;
        }, initial);
    }, [filteredMapData]);

    const clearWidthCount: Record<string, number> = React.useMemo(() => {
        const initial: Record<string, number> = {};
        return filteredMapData.features.reduce((accumulated, current) => {
            if (current.properties.width > 46) {
                if (accumulated["> 46in"]) {
                    accumulated["> 46in"]++;
                } else {
                    accumulated["> 46in"] = 1;
                }
            } else if (current.properties.width <= 46 && current.properties.width > 42) {
                if (accumulated["42 - 46in"]) {
                    accumulated["42 - 46in"]++;
                } else {
                    accumulated["42 - 46in"] = 1;
                }
            } else if (current.properties.width <= 42 && current.properties.width > 36) {
                if (accumulated["36 - 42in"]) {
                    accumulated["36 - 42in"]++;
                } else {
                    accumulated["36 - 42in"] = 1;
                }
            } else if (current.properties.width <= 36 && current.properties.width > 24) {
                if (accumulated["24 - 36in"]) {
                    accumulated["24 - 36in"]++;
                } else {
                    accumulated["24 - 36in"] = 1;
                }
            } else {
                if (accumulated["< 24in"]) {
                    accumulated["< 24in"]++;
                } else {
                    accumulated["< 24in"] = 1;
                }
            }

            return accumulated;
        }, initial);
    }, [filteredMapData]);

    const createPieChartSeries = (data: Record<string, number>): Array<{value: number; label: string}> => {
        return Object.keys(data)
            .map((dataKey, index) => {
                return {
                    value: Object.values(data)[index],
                    label: toProperCase(dataKey),
                };
            })
            .sort((a, b) => b.value - a.value);
    };

    if (indvDataError ?? panelDataError) return <LoadingError errorMessage={indvDataError?.message} />;
    else if (indvGeojson && panelData)
        return (
            <Grid container rowSpacing={{xs: 2}} columnSpacing={{xs: 2}}>
                <GridItem
                    xs={12}
                    lg={6}
                    p={false}
                    gridItemSx={{height: isSmallScreen ? "20vh" : "auto"}}
                    paperSx={{position: "relative"}}>
                    <Stack
                        direction="row"
                        gap={1}
                        sx={{position: "absolute", top: 0, left: 0, mt: 1, ml: 1, zIndex: 5}}>
                        {["cross_slope", "run_slope", "clear_width"].map(colorOption => (
                            <Chip
                                key={colorOption}
                                label={toProperCase(colorOption.split("_")[0]) + " " + colorOption.split("_")[1]}
                                size="small"
                                color={selectedMapView === colorOption ? "primary" : "default"}
                                onClick={() => setSelectedMapView(colorOption)}
                                sx={{
                                    [`&.${chipClasses.root}`]: {
                                        boxShadow: theme => theme.shadows[2],
                                    },
                                    [`&.${chipClasses.root}.${chipClasses.filledPrimary}`]: {
                                        backgroundColor: colors.lightBlue,
                                    },
                                    [`&.${chipClasses.root}.${chipClasses.filled}:not(.${chipClasses.filledPrimary})`]:
                                        {
                                            backgroundColor: theme => theme.palette.background.paper,
                                        },
                                }}
                            />
                        ))}
                    </Stack>
                    <ShiftControl {...{isShiftPressed}} top={4} right={4} scale={0.9} />
                    {selectedFeatures.length > 0 ? (
                        <Box position="absolute" top={0} right={0} mt={1} mr={1} zIndex={5}>
                            <Button
                                color="error"
                                startIcon={<Close />}
                                onClick={() => setSelectedFeatures([])}
                                variant="contained">
                                Clear selection ({selectedFeatures.length} features)
                            </Button>
                        </Box>
                    ) : (
                        <></>
                    )}
                    <DWMap
                        data={coloredMapData}
                        dataId="id"
                        multiSelect
                        borderRadius
                        onMultiSelect={(ids: string[]) => {
                            setSelectedFeatures(
                                ids
                                    .map(id => {
                                        const feature = coloredMapData.features.find(
                                            feature => feature.properties.id === parseInt(id),
                                        )!;
                                        return feature;
                                    })
                                    .filter(feature => feature !== undefined),
                            );
                        }}
                    />
                </GridItem>
                <GridItem xs={12} lg={6}>
                    <Stack direction="row">
                        <Stack gap={1} px={4}>
                            <Box>
                                <Typography>Cross slope</Typography>
                            </Box>
                            {Object.keys(crossSlopeCount).map((amount, index) => {
                                return (
                                    <Stack direction="row" key={amount} gap={2}>
                                        <TitlePill color={severityColors[index]}>{amount}</TitlePill>
                                        <Typography flexShrink={1} textAlign="right">
                                            {Object.values(crossSlopeCount)[index]}
                                        </Typography>
                                    </Stack>
                                );
                            })}
                        </Stack>
                        <Stack gap={1} px={4}>
                            <Box>
                                <Typography>Run slope</Typography>
                            </Box>
                            {Object.keys(runSlopeCount).map((severity, index) => {
                                return (
                                    <Stack direction="row" key={severity} gap={2}>
                                        <TitlePill flexBasis={75} color={severityColors[index]}>
                                            {toProperCase(severity)}
                                        </TitlePill>
                                        <Typography textAlign="right">{Object.values(runSlopeCount)[index]}</Typography>
                                    </Stack>
                                );
                            })}
                        </Stack>
                        <Stack gap={1} px={4}>
                            <Box>
                                <Typography>Clear width</Typography>
                            </Box>
                            {Object.keys(clearWidthCount)
                                .sort((a, b) => clearWidthBounds.indexOf(a) - clearWidthBounds.indexOf(b))
                                .map((amount, index) => {
                                    return (
                                        <Stack direction="row" key={amount} gap={2}>
                                            <TitlePill color={severityColors[index]}>{amount}</TitlePill>
                                            <Typography flexShrink={1}>
                                                {Object.values(clearWidthCount)[index]}
                                            </Typography>
                                        </Stack>
                                    );
                                })}
                        </Stack>
                    </Stack>
                </GridItem>
                <GridItem xs={12} sm={3} lg={3} title="Manual tag count">
                    <Stack>
                        {projectManualTags?.map(tag => {
                            return (
                                <Stack direction="row" key={tag.id} alignItems="center" gap={2}>
                                    <Box px={1} py={0.5} borderRadius={50} sx={{backgroundColor: tag.color + "33"}}>
                                        {tag.name}
                                    </Box>
                                    <Typography textAlign="right">{manualTagCount?.[tag.name]} features</Typography>
                                </Stack>
                            );
                        })}
                    </Stack>
                </GridItem>
                <GridItem xs={12} sm={9} lg={6}>
                    <Stack gap={2}>
                        <ToggleButtonGroup
                            exclusive
                            value={selectedChart}
                            onChange={handleChartChange}
                            aria-label="type of chart">
                            <ToggleButton value="cross_slope" aria-label="cross slope">
                                Cross slope
                            </ToggleButton>
                            <ToggleButton value="run_slope" aria-label="run slope">
                                Run slope
                            </ToggleButton>
                            <ToggleButton value="clear_width" aria-label="clear width">
                                Clear width
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <PieChart
                            colors={severityColors}
                            series={[
                                {
                                    data: createPieChartSeries(
                                        selectedChart === "cross_slope"
                                            ? crossSlopeCount
                                            : selectedChart === "run_slope"
                                            ? runSlopeCount
                                            : clearWidthCount,
                                    ),
                                },
                            ]}
                            width={600}
                            height={300}
                        />
                    </Stack>
                </GridItem>
                <Grid item xs={3}></Grid>
            </Grid>
        );
    else return <LoadingScreen />;
}
