import {Box, Typography, useTheme} from "@mui/material";
import React from "react";

const ComingSoon: React.FC = () => {
    const theme = useTheme();

    return (
        <Box height="100vh" display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={2}>
            <Typography variant="h1">Coming Soon</Typography>
            <Typography color={theme.palette.midnightBlue.light}>
                This page is under construction and will be available soon!
            </Typography>
        </Box>
    );
};

export default ComingSoon;
