import React from "react";
import {
    Box,
    Grid,
    type GridSize,
    Paper,
    darken,
    Tabs,
    Tab,
    tabClasses,
    Stack,
    Typography,
    type SxProps,
} from "@mui/material";
import {useProject} from "contexts/ProjectContext";
import {useQuery} from "@tanstack/react-query";
import {geojsonKeys, getGeojsonByType} from "queries/queries";
import type {IndividualFeatureProperties, PanelFeature, ReportType} from "react_ct/types";
import {colors} from "react_ct/theme";
import MileageDashboard from "./components/MileageDashboard";
import CollectionDashboard from "./components/CollectionDashboard";
import LoadingScreen from "components/LoadingScreen";
import PointsDashboard from "./components/PointsDashboard";

export function GridItem({
    xs,
    sm,
    md,
    lg,
    xl,
    title,
    children,
    p = true,
    gridItemSx,
    paperSx,
}: {
    xs?: boolean | GridSize;
    sm?: boolean | GridSize;
    md?: boolean | GridSize;
    lg?: boolean | GridSize;
    xl?: boolean | GridSize;
    title?: string;
    children?: string | JSX.Element | JSX.Element[];
    p?: boolean;
    gridItemSx?: SxProps;
    paperSx?: SxProps;
}): React.ReactElement {
    return (
        <Grid item {...{xs, sm, md, lg, xl}} sx={gridItemSx}>
            <Paper
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    boxSizing: "border-box",
                    p: p ? 4 : 0,
                    ...paperSx,
                }}>
                {title && <Typography mb={1}>{title}</Typography>}
                {children}
            </Paper>
        </Grid>
    );
}

function CustomTabPanel(props: {children?: React.ReactNode; index: number; value: number}): React.ReactElement {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{p: 3}}>{children}</Box>}
        </div>
    );
}

export default function HomePage(): React.ReactElement {
    const {
        currentProject: project,
        projectsError,
        projectScans,
        projectScansError,
        areProjectsPending,
        projectManualTags,
    } = useProject();

    const {data: baseData, error: baseDataError} = useQuery({
        queryKey: geojsonKeys.featuresGeojson(project?.id),
        queryFn: async ({queryKey}) => {
            return await getGeojsonByType(...(queryKey as [number | undefined, string, string]));
        },
        enabled: !!project,
    });

    const {data: indvData, error: indvDataError} = useQuery({
        queryKey: geojsonKeys.individualFeaturesGeojson(project?.id),
        enabled: !!project,
        queryFn: async ({queryKey}) => {
            return await getGeojsonByType(...(queryKey as [number | undefined, string, string]));
        },
    });

    const {data: obsData} = useQuery({
        queryKey: geojsonKeys.obstructionsGeojson(project?.id),
        enabled: !!project,
        queryFn: async ({queryKey}) => {
            return await getGeojsonByType(...(queryKey as [number | undefined, string, string]));
        },
    });

    const baseGeojson: GeoJSON.FeatureCollection<GeoJSON.LineString | GeoJSON.Point, ReportType> | undefined =
        baseData?.geojsonData as GeoJSON.FeatureCollection<GeoJSON.LineString | GeoJSON.Point, ReportType> | undefined;
    const indvGeojson: GeoJSON.FeatureCollection<GeoJSON.Point, IndividualFeatureProperties> | undefined =
        indvData?.geojsonData as GeoJSON.FeatureCollection<GeoJSON.Point, IndividualFeatureProperties> | undefined;
    const obsGeojson: GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.LineString, PanelFeature> | undefined =
        obsData?.geojsonData as GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.LineString, PanelFeature> | undefined;

    const [currentDashboard, setCurrentDashboard] = React.useState(0);

    const a11yProps = (
        index: number,
    ): {
        id: string;
        "aria-controls": string;
    } => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const changeDashboard = (event: React.SyntheticEvent, newValue: number): void => {
        setCurrentDashboard(newValue);
    };

    if (projectsError && !project)
        return (
            <Box>
                <Typography>Could not retrieve projects</Typography>
            </Box>
        );

    if (!project && areProjectsPending)
        return (
            <Box>
                <LoadingScreen />
            </Box>
        );

    return (
        <Box
            id="page-container"
            width="100%"
            height="auto"
            minHeight="100vh"
            boxSizing="border-box"
            position="relative"
            sx={{overflowX: "hidden"}}>
            <Paper elevation={2} sx={{width: "100%", px: 6, pt: 1, borderRadius: 0}}>
                <Stack direction="row" alignItems="baseline">
                    <Tabs value={currentDashboard} onChange={changeDashboard} sx={{flexGrow: 1}}>
                        <Tab
                            label="Midblocks"
                            sx={{
                                [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                    color: darken(colors.gray, 0.2),
                                },
                            }}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label="Curb Ramps"
                            sx={{
                                [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                    color: darken(colors.gray, 0.2),
                                },
                            }}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label="Collection"
                            sx={{
                                [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                    color: darken(colors.gray, 0.2),
                                },
                            }}
                            {...a11yProps(1)}
                        />
                        {project?.id === 39 && (
                            <Tab
                                label="Points"
                                sx={{
                                    [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                        color: darken(colors.gray, 0.2),
                                    },
                                }}
                                {...a11yProps(2)}
                            />
                        )}
                    </Tabs>
                    <Typography component="h2" variant="h5" flexBasis="33%">
                        {project?.name}
                    </Typography>
                </Stack>
            </Paper>
            <CustomTabPanel value={currentDashboard} index={0}>
                <MileageDashboard
                    {...{baseGeojson, baseDataError, obsGeojson, projectScans, projectScansError}}
                    filterOptions="midblock"
                />
            </CustomTabPanel>
            <CustomTabPanel value={currentDashboard} index={1}>
                <MileageDashboard
                    {...{baseGeojson, baseDataError, obsGeojson, projectScans, projectScansError}}
                    filterOptions="curb ramp"
                />
            </CustomTabPanel>
            <CustomTabPanel value={currentDashboard} index={2}>
                {project !== undefined && <CollectionDashboard project={project} />}
            </CustomTabPanel>
            {project?.id === 39 && (
                <CustomTabPanel value={currentDashboard} index={3}>
                    <PointsDashboard
                        {...{
                            indvGeojson,
                            indvDataError,
                            projectManualTags,
                        }}
                    />
                </CustomTabPanel>
            )}
        </Box>
    );
}
