import React, {type ReactNode, type ReactElement, useContext, useState} from "react";
import {
    type AlertProps,
    Box,
    Button,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    Typography,
} from "@mui/material";
import {colors} from "react_ct/theme";
import {hexToHSL} from "../components";
import {type ReportType} from "react_ct/types";
import {adminDataCategories, dataCategories, stageOptions, tableContents} from "../constants";
import {type FeatureCollection, type GeoJsonProperties} from "geojson";
import {AuthContext} from "contexts/AuthContext";
import {putScanStage} from "react_ct/requests";
import {DWAlert, SelectSingle} from "react_ct/components";

const firstTab = dataCategories[0]; // same for both admin and non-admin

export const TabPanel: React.FC<TabPanelProps> = props => {
    const {children, value, hidden, ...other} = props;
    const hslColor = hexToHSL(`${colors.blue}ff`);
    return (
        <Box
            role="tabpanel"
            hidden={hidden}
            id={`tabpanel-${value}`}
            aria-labelledby={`tab-${value}`}
            p={3}
            sx={theme => ({
                flexGrow: 1,
                backgroundColor: `hsl(${hslColor.hue}, ${hslColor.saturation - 8}%, ${hslColor.lum + 20}%)`,
                color: theme.palette.text.primary,
                borderRadius: `${value === firstTab ? 0 : theme.shape.borderRadius * 3}px ${
                    theme.shape.borderRadius * 3
                }px ${theme.shape.borderRadius * 3}px  ${theme.shape.borderRadius * 3}px`,
                transition: "border-radius 0.5s ease-in-out",
            })}
            {...other}>
            {!hidden && <Box>{children}</Box>}
        </Box>
    );
};

const NumberTableCell = (props: {children: ReactNode}): ReactElement => {
    return <TableCell sx={{textAlign: "right"}}>{props.children}</TableCell>;
};

/**
 *
 * @param props The inspection summary table props
 * @returns A table summarizing the inspection
 */
export const InspectionSummaryTable: React.FC<{
    report: ReportType;
    lastModified: Date | null;
}> = props => {
    const {report, lastModified} = props;
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="inspection summary table">
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <strong>Date</strong>
                        </TableCell>
                        <TableCell>{report.date}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Collector</strong>
                        </TableCell>
                        <TableCell>{report.collector}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Type</strong>
                        </TableCell>
                        <TableCell>{report.type ? "Curb Ramp" : "Midblock"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Detectable Warning</strong>
                        </TableCell>
                        <TableCell>{report.detectable_warning}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Sidewalk Area</strong>
                        </TableCell>
                        <TableCell>{report.combined_area} sf</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Material</strong>
                        </TableCell>
                        <TableCell>{report.material}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Length</strong>
                        </TableCell>
                        <TableCell>{report.length} feet</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Deterioration</strong>
                        </TableCell>
                        <TableCell>
                            {isNaN(Math.round((report.Total_Deteriorated_Area_area / report.combined_area) * 100))
                                ? "--"
                                : `${Math.round((report.Total_Deteriorated_Area_area / report.combined_area) * 100)}%`}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Last Update</strong>
                        </TableCell>
                        <TableCell>
                            {lastModified
                                ? `${lastModified.toLocaleDateString()} ${lastModified.toLocaleTimeString()}`
                                : "--"}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

/**
 *
 * @param props The inspection details table props
 * @returns A table detailing the inspection
 */
export const InspectionDetailsTable: React.FC<{
    report: ReportType;
}> = props => {
    const {report} = props;

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="inspection details table">
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <strong>Max. Run Slope</strong>
                        </TableCell>
                        <NumberTableCell>{report.max_run_slope.toFixed(1)}%</NumberTableCell>
                        <TableCell>
                            <strong>Avg. Run Slope</strong>
                        </TableCell>
                        <NumberTableCell>{report.avg_run_slope.toFixed(1)}%</NumberTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Max. Cross Slope</strong>
                        </TableCell>
                        <NumberTableCell>{report.max_cross_slope.toFixed(1)}%</NumberTableCell>
                        <TableCell>
                            <strong>Avg. Cross Slope</strong>
                        </TableCell>
                        <NumberTableCell>{report.avg_cross_slope.toFixed(1)}%</NumberTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Min. Clear Width</strong>
                        </TableCell>
                        <NumberTableCell>{Math.round(report.min_clear_width)} in</NumberTableCell>
                        <TableCell>
                            <strong>Avg. Clear Width</strong>
                        </TableCell>
                        <NumberTableCell>{Math.round(report.avg_width)} in</NumberTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Run Slope Score</strong>
                        </TableCell>
                        <NumberTableCell>{report.rs_score}</NumberTableCell>
                        <TableCell>
                            <strong>Deteriorated Area</strong>
                        </TableCell>
                        <NumberTableCell>
                            {report.Total_Deteriorated_Area_area !== undefined &&
                            !isNaN(report.Total_Deteriorated_Area_area)
                                ? `${report.Total_Deteriorated_Area_area} sf`
                                : "--"}
                        </NumberTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Cross Slope Score</strong>
                        </TableCell>
                        <NumberTableCell>{report.cs_score}</NumberTableCell>
                        <TableCell>
                            <strong>Brick Area</strong>
                        </TableCell>
                        <NumberTableCell>{report.Brick_area} sf</NumberTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Clear Width Score</strong>
                        </TableCell>
                        <NumberTableCell>{report.width_score}</NumberTableCell>
                        <TableCell>
                            <strong>Concrete Area</strong>
                        </TableCell>
                        <NumberTableCell>{report.Panel_area} sf</NumberTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

/**
 *
 * @returns A table containing the accessibility score legend
 */
export const TableLegend: React.FC = () => {
    return (
        <Paper sx={theme => ({p: 2, boxSizing: "border-box", borderRadius: theme.shape.borderRadius})}>
            <Stack gap={1}>
                {tableContents.map(item => {
                    const hslColor = hexToHSL(`${item.color}ff`);
                    return (
                        <Stack
                            key={item.id}
                            direction="row"
                            alignItems="center"
                            gap={1}
                            sx={{
                                backgroundColor: `${item.color}44`,
                                borderRadius: 50,
                                p: "2px",
                                boxSizing: "border-box",
                                border: `1px solid ${item.color}34`,
                                borderTop: "none",
                            }}>
                            <Box
                                width="30px"
                                height="30px"
                                borderRadius="50%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{backgroundColor: item.color}}>
                                <Typography fontWeight="bold" color="white">
                                    {item.score ?? "--"}
                                </Typography>
                            </Box>
                            <Typography
                                fontSize="0.9rem"
                                color={`hsl(${hslColor.hue - 2}, ${hslColor.saturation - 3}%, ${hslColor.lum - 35}%, ${
                                    hslColor.alpha
                                })`}>
                                {item.description}
                            </Typography>
                        </Stack>
                    );
                })}
            </Stack>
        </Paper>
    );
};

const AdminTable: React.FC<{report: ReportType}> = props => {
    const {report} = props;
    const {id: scanId} = report;

    const [stage, setStage] = useState(stageOptions[0]);

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<{
        severity: AlertProps["severity"];
        message: string;
    }>();

    const updateScanStage = async (): Promise<void> => {
        setLoading(true);
        try {
            const resp = await putScanStage(scanId, stage.name);
            if (resp.status === 200) {
                setAlert({
                    severity: "success",
                    message: "Scan updated successfully!",
                });
            } else {
                console.error(resp);
                setAlert({
                    severity: "error",
                    message: "Could not update scan",
                });
            }
        } catch (e) {
            console.error(e);
            setAlert({
                severity: "error",
                message: "Could not update scan",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper sx={theme => ({p: 2, boxSizing: "border-box", borderRadius: theme.shape.borderRadius})}>
            <Box
                sx={theme => ({
                    p: 2,
                    boxSizing: "border-box",
                    borderRadius: theme.shape.borderRadius,
                })}
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={"row"}
                alignItems={"center"}>
                <SelectSingle<{name: string}>
                    label="Stage"
                    options={stageOptions}
                    dataKey={"name"}
                    selectedOption={stage}
                    setSelectedOption={setStage}
                />
                <Button variant="contained" color="primary" disabled={loading} onClick={updateScanStage}>
                    Update Scan {scanId}
                </Button>
            </Box>
            <DWAlert
                openAlert={!!alert}
                onClose={() => {
                    setAlert(undefined);
                }}
                alertSeverity={alert?.severity}
                alertMessage={alert?.message}
            />
        </Paper>
    );
};

export const InspectionTabs: React.FC<{
    currentDataCategory: DataCategory;
    handleChange: (event: React.SyntheticEvent, newValue: DataCategory) => void;
    geojsonData: FeatureCollection<GeoJSON.Geometry, GeoJsonProperties>;
    selectedReportIndex: number;
    lastModified: Date | null;
}> = props => {
    const {currentDataCategory, handleChange, geojsonData, selectedReportIndex, lastModified} = props;

    const auth = useContext(AuthContext);

    const activeDataCategories = auth.checkIsAdmin?.() ? adminDataCategories : dataCategories;
    return (
        <Box sx={{flexGrow: 1, display: "flex", flexDirection: "column"}}>
            <Box sx={{position: "relative", zIndex: 2}}>
                <Tabs
                    value={currentDataCategory}
                    textColor="primary"
                    onChange={handleChange}
                    sx={{
                        flexBasis: "48px",
                        "& .MuiTabs-indicator": {
                            display: "none",
                        },
                    }}>
                    {activeDataCategories.map(category => {
                        const hslColor = hexToHSL(`${colors.blue}ff`);
                        return (
                            <Tab
                                key={category}
                                value={category}
                                label={category}
                                sx={theme => ({
                                    color: theme.palette.midnightBlue.light,
                                    "&.Mui-selected": {
                                        backgroundColor: `hsl(${hslColor.hue}, ${hslColor.saturation - 8}%, ${
                                            hslColor.lum + 20
                                        }%)`,
                                        color: theme.palette.text.secondary,
                                        borderTopLeftRadius: theme.shape.borderRadius,
                                        borderTopRightRadius: theme.shape.borderRadius,
                                    },
                                })}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            {activeDataCategories.map(category => (
                <TabPanel key={category} hidden={currentDataCategory !== category} value={category}>
                    {category === "Inspection Summary" && (
                        <InspectionSummaryTable
                            report={geojsonData?.features[selectedReportIndex].properties as ReportType}
                            lastModified={lastModified}
                        />
                    )}
                    {category === "Inspection Details" && (
                        <InspectionDetailsTable
                            report={geojsonData?.features[selectedReportIndex].properties as ReportType}
                        />
                    )}
                    {category === "Inspection Legend" && <TableLegend />}
                    {Boolean(category === "Admin" && auth.checkIsAdmin?.()) && (
                        <AdminTable report={geojsonData?.features[selectedReportIndex].properties as ReportType} />
                    )}
                </TabPanel>
            ))}
        </Box>
    );
};
